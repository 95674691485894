import {Component, Input} from '@angular/core';
import {IVehicle} from '@service-and-repairs/awpintegrationlib';

@Component({
  selector: 'app-vehicle-data-overlay',
  templateUrl: 'vehicle-data-overlay.html',
  styleUrls: ['vehicle-data-overlay.scss']
})
export class VehicleDataOverlayComponent {
  @Input()
  vehicle: IVehicle;

  @Input()
  mileage: string;

  @Input()
  locale: string;
}
