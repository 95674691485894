import {Component, Input} from '@angular/core';
import {environment} from '@environments/environment';
import {OidcToken} from '../../auth/oidc/oidc-token';
import {SupportInformationService} from '../../core/support/support-information.service';
import {UserData} from '../../core/user/models/user-data';
import {UserService} from '../../core/user/services/user.service';
import {AwpClientLibService} from '../../services/awp-client-lib.service';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent {
  @Input()
  viewState: {userDropdownVisible: boolean, settingsDialogVisible: boolean};

  user: UserData;

  constructor(private userService: UserService,
              private awpClientLibService: AwpClientLibService,
              public supportInformationService: SupportInformationService) {
    this.userService.userSubject.subscribe((userData: UserData) => this.user = userData);
  }

  toggleUserDropdown(): void {
    this.viewState.userDropdownVisible = !this.viewState.userDropdownVisible;
  }

  closeUserDropdown(): void {
    this.viewState.userDropdownVisible = false;
  }

  openSettings(): void {
    this.closeUserDropdown();
    this.viewState.settingsDialogVisible = true;
  }

  logOut(): void {
    this.userService.logOut();
  }

  copySupportInformation(): void {
    this.copyToClipboard(
      this.supportInformationService.collectSupportInformation(this.user),
      'Copied support information.'
    );
    this.closeUserDropdown();
  }

  copyAccessToken(): void {
    const oidcToken = OidcToken.readFromStorage(this.userService.userSubject.getValue().getContext());
    this.copyToClipboard(oidcToken.access_token, 'Access token copied to clipboard.');
    this.closeUserDropdown();
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  copyAccessTokenVisible(): boolean {
    return !['PROD', 'PROD_US', 'PROD_CN'].includes(environment.deploymentEnvironment);
  }

  private copyToClipboard(text: string, successMessage: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => this.awpClientLibService.serverApi.notifySuccess(successMessage))
      .catch(() => this.copyToClipboardForThinClientAndOlderBrowsers(text, successMessage));
  }

  private copyToClipboardForThinClientAndOlderBrowsers(text: string, successMessage: string): void {
    const element = document.createElement('textarea');
    document.body.appendChild(element);
    element.value = text;
    element.select();
    document.execCommand('copy', false);
    element.remove();
    this.awpClientLibService.serverApi?.notifySuccess(successMessage);
  }
}
