import {Component} from '@angular/core';
import {SelectListOption} from '@bmw-ds/components';
import {TranslateService} from '@ngx-translate/core';
import {Outlet} from '../../core/dealer/models/outlet';
import {DealerDataService} from '../../core/dealer/services/dealer-data.service';
import {UserService} from '../../core/user/services/user.service';
import {VisibilityService} from '../../core/user/services/visibility.service';
import {VehicleType} from "@service-and-repairs/awpintegrationlib";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  selectedOutlet: Outlet;
  outletDropdownOptions: SelectListOption[] = [];
  selectedOutletId: string | string[];

  viewState = {
    settingsDialogVisible: false,
    userDropdownVisible: false,
    configurationDialogVisible: false,
    imprintVisible: false
  };

  constructor(public visibilityService: VisibilityService,
              public dealerDataService: DealerDataService,
              private translate: TranslateService,
              private userService: UserService) {
    this.selectedOutlet = dealerDataService.getSelectedOutlet();
    this.userService.userSubject.subscribe(() =>
      this.selectedOutletId = this.dealerDataService.outlets
        ?.indexOf(this.dealerDataService.getSelectedOutlet()).toString()
    );

    this.createOutletDropdownData();

    this.translate.onLangChange.subscribe(this.createOutletDropdownData.bind(this));
  }

  openConfiguration(): void {
    document.getElementById('awp-frontend-configuration')?.['api'].show();
    this.viewState.configurationDialogVisible = true;
  }

  outletDropdownChanged(): void {
    this.selectedOutlet = this.dealerDataService.outlets[this.selectedOutletId as string];
    this.dealerDataService.setSelectedOutlet(this.selectedOutlet);
  }

  private createOutletDropdownData(): void {
    this.outletDropdownOptions = [];
    if (this.dealerDataService.outlets) {
      this.dealerDataService.outlets.forEach((outlet: Outlet, index: number) => {
        this.outletDropdownOptions.push({
          label: outlet.toString(this.translate),
          id: index.toString(),
          icon: outlet.vehicleType === VehicleType.MOTORCYCLE ? 'motorcycle' : 'car'
        });

        // initial value selection based on id, which is just the index of the selected outlet
        const businessPartner = outlet.businessPartner;
        const selectedBusinessPartner = this.selectedOutlet.businessPartner;
        if (businessPartner.getDistributionPartnerNumber() === selectedBusinessPartner.getDistributionPartnerNumber()
          && businessPartner.getOutletNumber() === selectedBusinessPartner.getOutletNumber()
          && outlet.vehicleType === this.selectedOutlet.vehicleType) {
          this.selectedOutletId = index.toString();
        }
      });
      this.outletDropdownOptions.sort((a: SelectListOption, b: SelectListOption) => a.label.localeCompare(
        b.label,
        undefined,
        {numeric: true}
      ));
    }
  }
}
