import {Injectable} from '@angular/core';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {ServiceCaseSession} from '../interfaces/service-case.session';
import {ServiceCaseSessionService} from './service-case-session.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseSessionLoader {
  constructor(private readonly sessionService: ServiceCaseSessionService,
              private readonly userService: UserService) {
  }

  getSessions(vin: string): Promise<ServiceCaseSession[]> {
    const user: UserData = this.userService.userSubject.getValue();
    if (user?.getContext() === 'B2E' || vin?.length !== 17) {
      return Promise.resolve([]);
    }

    return this.sessionService.getSessions(vin, user);
  }
}
