import {Injectable} from '@angular/core';
import {Route} from '@angular/router';
import {IframeComponent} from '../components/iframe/iframe.component';
import {WebComponentComponent} from '../components/web-component/web-component.component';
import {UserData} from '../core/user/models/user-data';
import {UserService} from '../core/user/services/user.service';
import {IIframeAppConfiguration} from "../interfaces/IIframeAppConfiguration";
import {IWebComponentAppConfiguration} from "../interfaces/IWebComponentAppConfiguration";
import {WebComponentAttributeService} from "../integration/web-component-attribute.service";
import {IframeParamService} from "../integration/iframe-param.service";
import {WebComponentEventService} from "../integration/web-component-event.service";

@Injectable({
  providedIn: 'root'
})
export class RouteFactoryService {
  user: UserData;

  constructor(userService: UserService,
              private iframeParamService: IframeParamService,
              private webComponentAttributeService: WebComponentAttributeService,
              private webComponentEventService: WebComponentEventService) {
    userService.userSubject.subscribe((user: UserData) => this.user = user);
  }

  static isIframeApp(app: IIframeAppConfiguration | IWebComponentAppConfiguration): app is IIframeAppConfiguration {
    return app && (app as IIframeAppConfiguration).iFrameUrls !== undefined;
  }

  getRouteForApp(app: IIframeAppConfiguration | IWebComponentAppConfiguration): Route {
    if (RouteFactoryService.isIframeApp(app)) {
      return {
        path: app.routingPath,
        component: IframeComponent,
        data: {
          iFrameUrl: this.getIframeUrlForEnvironment(app),
          webAgentUrl: this.getUrlForWebAgent(app),
          pathParam: IframeParamService.getPathParamForApp(app),
          getAttributes: () => {
            return this.iframeParamService.getQueryParamsForApp(app);
          },
          appConfiguration: app
        }
      };
    } else {
      // web component app
      return {
        path: app.routingPath,
        component: WebComponentComponent,
        data: {
          webComponentUrls: this.getWebComponentUrlsForEnvironment(app),
          getAttributes: () => {
            return this.webComponentAttributeService.getAttributesForApp(app);
          },
          getEventListeners: this.webComponentEventService.getEventListenersForApp(app),
          appConfiguration: app
        }
      };
    }
  }

  private getIframeUrlForEnvironment(app: IIframeAppConfiguration): string {
    const context = this.user?.getContext();
    if (context === 'B2D') {
      return app.iFrameUrls.b2d;
    } else if (context === 'B2D_INTERNAL') {
      return app.iFrameUrls.b2dIntranet;
    } else {
      return app.iFrameUrls.b2e;
    }
  }

  private getWebComponentUrlsForEnvironment(app: IWebComponentAppConfiguration): string[] {
    const context = this.user?.getContext();
    let urls: string | string[];
    if (context === 'B2D') {
      urls = app.webComponentUrls.b2d;
    } else if (context === 'B2D_INTERNAL') {
      urls = app.webComponentUrls.b2dIntranet;
    } else {
      urls = app.webComponentUrls.b2e;
    }
    if (!urls) {
      return [];
    } else if (typeof urls === 'string') {
      return [urls];
    } else {
      return urls;
    }
  }

  private getUrlForWebAgent(app: IIframeAppConfiguration): string {
    if (!app.webAgentSecuredPopupUrls || !this.user) {
      return '';
    }
    if (this.user.getContext() === 'B2D') {
      return app.webAgentSecuredPopupUrls.b2d || '';
    } else if (this.user.getContext() === 'B2D_INTERNAL') {
      return app.webAgentSecuredPopupUrls.b2dIntranet || '';
    } else {
      return app.webAgentSecuredPopupUrls.b2e || '';
    }
  }
}
