import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ITypeAttributes, IVehicle, Vehicle} from '@service-and-repairs/awpintegrationlib';
import {CosyService} from '../../cosy/cosy.service';
import {UserData} from '../../user/models/user-data';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private httpClient: HttpClient) {
  }

  getVehicles(vin: string, user: UserData): Promise<IVehicle[]> {
    return new Promise((resolve, reject) => {
      const userParameters = `?locale=${user.getLocale()}&country=${user.getB2XCountryCode()}`;
      const businessPartner = user.getBusinessPartner();
      const dealerParameters = businessPartner ?
        `&distribution_partner_number=${businessPartner.getDistributionPartnerNumber()}&outlet_number=${businessPartner.getOutletNumber()}` :
        '';
      this.httpClient.get('/api/vehicle/v2/' + vin + userParameters + dealerParameters, {withCredentials: true})
        .subscribe({
          next: (response: any) => {
            const vehicles = response.data.vehicles.map((rawVehicle: any) => CosyService.addBasePathToCosyUrls(
              Vehicle.fromRawData(rawVehicle),
              user.getContext()
            ));
            resolve(vehicles);
          },
          error: reason => reject(reason)
        });
    });
  }

  getVirtualVehicle(typeCode: string, user: UserData): Promise<IVehicle> {
    return new Promise((resolve, reject): void => {
      let parameters: string = `?locale=${user.getLocale()}&country=${user.getB2XCountryCode()}`;
      if (!user.isB2E()) {
        parameters = parameters
          + `&distribution_partner_number=${user.getBusinessPartner()?.getDistributionPartnerNumber()}`
          + `&outlet_number=${user.getBusinessPartner()?.getOutletNumber()}`;
      }
      this.httpClient.get('/api/typecode/v1/' + typeCode + parameters, {withCredentials: true})
        .subscribe({
          next: (vehicle: ITypeAttributes) => resolve(CosyService.addBasePathToCosyUrls(
            Vehicle.createVirtualVehicle(vehicle),
            user.getContext()
          )),
          error: reason => reject(reason)
        });
    });
  }
}
