import {ActorId} from '@service-and-repairs/awpintegrationlib';
import {IEnvironment} from '../app/interfaces/IEnvironment';

/**
 * Configuration for DEV_TEST environments (awp-test.bmwgroup.net, awp-test-b2d.bmwgroup.net, awp-test.bmwgroup.com).
 * Replaces environment.ts if the Angular build configuration 'DEV_TEST' is used.
 */
export const environment: IEnvironment = {
  production: false,
  deploymentEnvironment: 'DEV_TEST',
  hub: 'EMEA',
  categories: [
    {
      titleResource: 'categories.serviceCases',
      apps: [
        {
          titleResource: 'apps.caseOverview',
          icon: 'list',
          webComponentUrls: {
            b2d: '/ui/service-case/main.js',
            b2dIntranet: '/ui/service-case/main.js',
            b2e: '/ui/service-case/main.js'
          },
          webComponentCustomTag: 'ueg-awp-service-case-overview',
          hideCaseBar: true,
          routingPath: 'caseoverview'
        },
        {
          titleResource: 'apps.caseDetails',
          icon: 'todo',
          webComponentUrls: {
            b2d: '/ui/service-case/main.js',
            b2dIntranet: '/ui/service-case/main.js',
            b2e: '/ui/service-case/main.js'
          },
          webComponentCustomTag: 'ueg-awp-service-case-details',
          routingPath: 'casedetails',
          serviceCaseRequired: true
        }
      ]
    },
    {
      titleResource: 'categories.vehicle',
      apps: [
        {
          titleResource: 'apps.vehicleDetails',
          icon: 'car',
          webComponentUrls: {
            b2d: '/ui/vehicle/main.js',
            b2dIntranet: '/ui/vehicle/main.js',
            b2e: '/ui/vehicle/main.js'
          },
          additionalSources: {
            b2d: [
              'https://ckf-qa.bmwgroup.com/wc/remote-key-read/trigger/main.js'
            ],
            b2dIntranet: [
              'https://ckf-qa.bmwgroup.net/wc/remote-key-read/trigger/main.js'
            ]
          },
          webComponentCustomTag: 'ueg-awp-vehicle-details',
          routingPath: 'vehicledetails',
          vehicleRequired: true
        },
        {
          titleResource: 'apps.history',
          icon: 'history',
          webComponentUrls: {
            b2d: 'https://air-services-b2d-qa2-wen-oidc.bmwgroup.com/ui/vehicle-history/main.js',
            b2dIntranet: 'https://air-services-internal-qa2.bmwgroup.net/ui/vehicle-history/main.js',
            b2e: 'https://air-services-internal-qa2.bmwgroup.net/ui/vehicle-history/main.js'
          },
          b2dRoles: ['awp_b2d_vehiclehistory'],
          webComponentCustomTag: 'air-vehicle-history',
          routingPath: 'vehiclehistory',
          vinLongRequired: true
        },
        {
          titleResource: 'apps.diagnosticProtocols',
          icon: 'repeat',
          webComponentUrls: {
            b2d: 'https://scc-apps-dev.aws.bmw.cloud/vsm-ui/main.js',
            b2dIntranet: 'https://scc-apps-dev.eu-central-1.aws.cloud.bmw/vsm-ui/main.js'
          },
          b2dRoles: ['awp_b2d_diagnosticprotocols'],
          webComponentCustomTag: 'vehicle-session-viewer',
          routingPath: 'diagnosticprotocols',
          vinLongRequired: true
        },
        {
          titleResource: 'apps.digitalWorkshopFile',
          icon: 'file_text',
          webComponentUrls: {
            b2d: 'https://www.scc-apps-dev.aws.bmw.cloud/dwf-ui/main.js',
            b2dIntranet: 'https://www.scc-dev.apps.4wm-scc-integration.eu-central-1.aws.cloud.bmw/dwf-ui/main.js'
          },
          webComponentCustomTag: 'scc-dwf-app',
          routingPath: 'digitalworkshopfile',
          vinLongRequired: true
        },
        {
          titleResource: 'RITA',
          icon: 'manual_work',
          iFrameUrls: {
            b2d: 'https://www.rita.azure.bmw.cloud/qa/0.0.0?domain=internet',
            b2dIntranet: 'https://www.rita.azure.bmw.cloud/qa/0.0.0?domain=intranet',
            b2e: 'https://www.rita.azure.bmw.cloud/qa/0.0.0?domain=b2e'
          },
          b2dRoles: [
            'mywosh_admin_dealer_b2d',
            'mywosh_access_b2d',
            'mywosh_vehicle_write_b2d',
            'mywosh_admin_fieldtest_b2d',
            'mywosh_fieldtester_b2d',
            'mywosh_pilot_dealer_b2d'
          ],
          routingPath: 'rita',
          vinLongRequired: true
        },
        {
          titleResource: 'Technipedia',
          icon: 'book',
          iFrameUrls: {
            b2d: 'https://technipedia-int.bmwgroup.com',
            b2dIntranet: 'https://technipedia-int.bmwgroup.net',
            b2e: 'https://technipedia-int.bmwgroup.net'
          },
          b2dRoles: ['dokunext_user_read'],
          routingPath: 'technipedia'
        }
      ]
    },
    {
      titleResource: 'categories.leadandcustomer',
      apps: [
        {
          titleResource: 'apps.servicepreparation',
          icon: 'edit_line',
          webComponentUrls: {
            b2d: 'https://sparc-int.aws.bmw.cloud/sparc-serviceprep-3/sparc-service-prep-wrapper-v3.js',
            b2dIntranet: 'https://sparc-int.aws.bmw.cloud/sparc-serviceprep-3/sparc-service-prep-wrapper-v3.js'
          },
          webComponentCustomTag: 'sparc-service-prep',
          b2dRoles: ['awp_b2d_servicepreparation'],
          routingPath: 'servicepreparation',
          vinLongRequired: true
        },
        {
          titleResource: 'apps.fleet',
          icon: 'service_leads',
          webComponentUrls: {
            b2d: '/api/gui/leads/cockpit/main.js',
            b2dIntranet: '/api/gui/leads/cockpit/main.js'
          },
          webComponentCustomTag: 'leads-cockpit-fleet',
          b2dRoles: ['awp_b2d_customerfleet'],
          routingPath: 'serviceleads',
          hideCaseBar: true
        },
        {
          titleResource: 'apps.servicedemand',
          icon: 'service_requirements',
          webComponentUrls: {
            b2d: '/api/gui/leads/cockpit/main.js',
            b2dIntranet: '/api/gui/leads/cockpit/main.js'
          },
          additionalSources: {
            b2d: [
              '/ui/live-diagnosis-component/main.js',
              '/ui/tyre-service-component/main.js',
              '/ui/battery-health-component/main.js',
              'https://air-services-b2d-qa2-wen-oidc.bmwgroup.com/ui/qdm/main.js',
              'https://ckf-int.bmwgroup.com/wc/service-planning-component/main.js',
              'https://ckf-int.bmwgroup.com/ui/check-control-messages/main.js',
              'https://cute-apps-dev.aws.bmw.cloud/service-contracts/main.js',
              'https://ckf-int.bmwgroup.com/wc/remote-key-read/request/main.js',
              'https://cute-apps-dev.aws.bmw.cloud/service-ride/main.js',
              'https://ckf-int.bmwgroup.com/ui/fault-codes/main.js',
              'https://air-services-b2d-qa2-wen-oidc.bmwgroup.com/ui/measure/sel/main.js',
              'https://air-services-b2d-qa2-wen-oidc.bmwgroup.com/ui/measure/service-bulletin/details/main.js',
              'https://ckf-int.bmwgroup.com/ui/mileage/main.js'
            ],
            b2dIntranet: [
              '/ui/live-diagnosis-component/main.js',
              '/ui/tyre-service-component/main.js',
              '/ui/battery-health-component/main.js',
              'https://air-services-internal-qa2.bmwgroup.net/ui/qdm/main.js',
              'https://ckf-int.bmwgroup.net/wc/service-planning-component/main.js',
              'https://ckf-int.bmwgroup.net/ui/check-control-messages/main.js',
              'https://internal.cute-apps-dev.eu-central-1.aws.cloud.bmw/service-contracts/main.js',
              'https://ckf-int.bmwgroup.net/wc/remote-key-read/request/main.js',
              'https://internal.cute-apps-dev.eu-central-1.aws.cloud.bmw/service-ride/main.js',
              'https://ckf-int.bmwgroup.net/ui/fault-codes/main.js',
              'https://air-services-internal-qa2.bmwgroup.net/ui/measure/sel/main.js',
              'https://air-services-internal-qa2.bmwgroup.net/ui/measure/service-bulletin/details/main.js',
              'https://ckf-int.bmwgroup.net/ui/mileage/main.js'
            ]
          },
          webComponentCustomTag: 'leads-cockpit-vehicle',
          b2dRoles: ['awp_b2d_leads'],
          routingPath: 'servicedemand',
          vinLongRequired: true
        },
        {
          titleResource: 'apps.dealerCockpit',
          icon: 'store',
          iFrameUrls: {
            b2d: 'https://dealercockpit2-e2e.bmwgroup.com/tsdc/pages/vehicle-information.xhtml',
            b2dIntranet: 'https://dealercockpit2-e2e.bmwgroup.net/tsdc/pages/vehicle-information.xhtml'
          },
          b2dRoles: ['awp_b2d_dealercockpit'],
          routingPath: 'dealercockpit'
        },
        {
          titleResource: 'CCR Car Park',
          icon: 'calendar_car',
          webComponentUrls: {
            b2dIntranet: 'https://dealercockpit2-e2e.bmwgroup.net/tsdc/web-components/ccr-car-park/non-prod/current/main.js'
          },
          b2dRoles: ['cddc_ccr_carpark'],
          routingPath: 'ccrcarpark',
          webComponentCustomTag: 'we-ccr-carpark',
          hideCaseBar: true
        },
        {
          titleResource: 'apps.customerData',
          icon: 'folder_user',
          webComponentUrls: {
            b2d: 'https://cdf-test.rspretailcrm.com/main.js',
            b2dIntranet: 'https://cdf-test.ext.eu-central-1.aws.cloud.bmw/main.js'
          },
          webComponentCustomTag: 'customer-data-frontend',
          b2dRoles: [
            'cdf_user',
            'cdf_admin_global',
            'cdf_admin_local_write',
            'cdf_admin_local_read'
          ],
          routingPath: 'customerdata',
          hideCaseBar: true
        },
        {
          titleResource: 'apps.voiceofcustomer',
          icon: 'user_voice',
          iFrameUrls: {
            b2d: 'https://poco-int.bmwgroup.com/customerBoard/AWPInterface',
            b2dIntranet: 'https://poco-int.bmwgroup.net/customerBoard/AWPInterface'
          },
          webAgentSecuredPopupUrls: {
            b2d: 'https://poco-int.bmwgroup.com/customerBoard/auth.html',
            b2dIntranet: 'https://poco-int.bmwgroup.net/customerBoard/auth.html'
          },
          b2dRoles: [
            'fr_dp_customerboard_access',
            'fr_dp_customerboard_access_nsc',
            'fr_dp_customerboard_access_admin'
          ],
          routingPath: 'voiceofcustomer',
          vinLongRequired: true
        },
        {
          titleResource: 'Dealer Dashboard',
          icon: 'dashboard',
          iFrameUrls: {
            b2d: 'https://www.core-dashboard-dev.aws.bmw.cloud/dash/index.html'
          },
          routingPath: 'dealerdashboard'
        }
      ]
    },
    {
      titleResource: 'categories.parts',
      apps: [
        {
          titleResource: 'apps.etk',
          icon: 'etk',
          iFrameUrls: {
            b2d: 'https://etk-cloud-eu-qa.bmwgroup.com',
            b2dIntranet: 'https://etk-cloud-eu-qa.bmwgroup.net',
            b2e: 'https://etk-cloud-eu-qa.bmwgroup.net'
          },
          b2dRoles: ['awp_b2d_etk'],
          routingPath: 'etk',
          actorId: ActorId.PARTS_CATALOGUE
        },
        {
          titleResource: 'Parts Catalog',
          icon: 'etk',
          webComponentUrls: {
            b2d: 'https://catalog-research-int.aws.bmw.cloud/ui/static/parts-catalog/main.js',
            b2e: 'https://catalog-research-int.eu-central-1.aws.cloud.bmw/ui/static/parts-catalog/main.js',
            b2dIntranet: 'https://catalog-research-int.eu-central-1.aws.cloud.bmw/ui/static/parts-catalog/main.js'
          },
          routingPath: 'partssearch',
          webComponentCustomTag: 'cars-parts-catalog',
          vehicleRequired: true
        },
        {
          titleResource: 'apps.partsOrdering',
          icon: 'shopping_cart',
          iFrameUrls: {
            b2d: 'https://parts-ordering.opus-test.bmwgroup.com',
            b2dIntranet: 'https://parts-ordering.opus-test.eu-central-1.aws.cloud.bmw'
          },
          routingPath: 'partsordering',
          b2dRoles: [
            'OPUS_Ordering',
            'OPUS_Admin',
            'OPUS_Market_Administration'
          ],
          hideCaseBar: true
        },
        {
          titleResource: 'apps.orderOverview',
          icon: 'box_closed',
          iFrameUrls: {
            b2d: 'https://order-overview.opus-test.bmwgroup.com',
            b2dIntranet: 'https://order-overview.opus-test.eu-central-1.aws.cloud.bmw'
          },
          routingPath: 'orderoverview',
          b2dRoles: [
            'OPUS_Order_Overview',
            'OPUS_Admin',
            'OPUS_Market_Administration'
          ],
          hideCaseBar: true
        },
        {
          titleResource: 'apps.partsInfo',
          icon: 'parts_info',
          iFrameUrls: {
            b2d: 'https://parts-info.opus-test.bmwgroup.com',
            b2dIntranet: 'https://parts-info.opus-test.eu-central-1.aws.cloud.bmw'
          },
          routingPath: 'partsinfo',
          b2dRoles: [
            'OPUS_Parts_Info',
            'OPUS_Admin',
            'OPUS_Market_Administration'
          ],
          hideCaseBar: true
        },
        {
          titleResource: 'apps.partsOrderDocuments',
          icon: 'version',
          iFrameUrls: {
            b2d: 'https://documents.opus-test.bmwgroup.com/',
            b2dIntranet: 'https://documents.opus-test.eu-central-1.aws.cloud.bmw/'
          },
          routingPath: 'partsorderdocuments',
          b2dRoles: [
            'OPUS_Documents',
            'OPUS_Admin',
            'OPUS_Market_Administration'
          ],
          hideCaseBar: true
        }
      ]
    },
    {
      titleResource: 'categories.serviceInformation',
      apps: [
        {
          titleResource: 'apps.air',
          icon: 'air',
          iFrameUrls: {
            b2d: 'https://myair-b2d-qa2.bmwgroup.com/air',
            b2dIntranet: 'https://myair-b2d-qa2.bmwgroup.net/air',
            b2e: 'https://myair-b2e-qa2.bmwgroup.net/air'
          },
          webAgentSecuredPopupUrls: {
            b2d: 'https://myair-b2d-qa2.bmwgroup.com/auth.html',
            b2dIntranet: 'https://myair-b2d-qa2.bmwgroup.net/auth.html',
            b2e: 'https://myair-b2e-qa2.bmwgroup.net/auth.html'
          },
          b2dRoles: ['awp_b2d_air', 'awp_b2d_air_mot'],
          routingPath: 'air',
          actorId: ActorId.INFORMATION_RESEARCH
        },
        {
          titleResource: 'apps.printCenter',
          icon: 'printer',
          webComponentUrls: {
            b2d: 'https://www.core-apps-dev.aws.bmw.cloud/pdp-ui/main.js',
            b2dIntranet: 'https://www.core-dev.apps.4wm-scc-integration.eu-central-1.aws.cloud.bmw/pdp-ui/main.js'
          },
          b2dRoles: ['awp_b2d_printcenter'],
          webComponentCustomTag: 'print-data-provider',
          routingPath: 'printcenter',
          vinLongRequired: true
        },
        {
          titleResource: 'apps.flatrateSearch',
          icon: 'manual_work',
          webComponentUrls: {
            b2d: 'https://air-services-b2d-qa2-wen-oidc.bmwgroup.com/ui/flatrate-research/main.js',
            b2dIntranet: 'https://air-services-internal-qa2.bmwgroup.net/ui/flatrate-research/main.js',
            b2e: 'https://air-services-internal-qa2.bmwgroup.net/ui/flatrate-research/main.js'
          },
          b2dRoles: ['awp_b2d_flaterateunits'],
          webComponentCustomTag: 'air-flatrate-powersearch',
          routingPath: 'flatratesearch',
          vinLongRequired: true
        },
        {
          titleResource: 'apps.wvi',
          icon: 'wvi',
          iFrameUrls: {
            b2d: 'https://warrweb.warrapps-t.aws.bmw.cloud/warranty/jsp/VI/ShowVIData.jsp',
            b2dIntranet: 'https://warrweb.warrapps-t.eu-central-1.aws.cloud.bmw/warranty/jsp/VI/ShowVIData.jsp',
            b2e: 'https://warrweb.warrapps-t.eu-central-1.aws.cloud.bmw/warranty/jsp/VI/ShowVIData.jsp'
          },
          b2dRoles: ['s_gate_us_user_'],
          routingPath: 'wvi',
          vinLongRequired: true
        },
        {
          titleResource: 'apps.stabi',
          icon: 'tools',
          iFrameUrls: {
            b2dIntranet: 'https://stabi-intranet-i.bmwgroup.net/swz_web/faces/onlinecat/onlinecat.xhtml'
          },
          webAgentSecuredPopupUrls: {
            b2dIntranet: 'https://stabi-intranet-i.bmwgroup.net/swz_web/faces/awp/popup.auth.html'
          },
          routingPath: 'stabi'
        },
        {
          titleResource: 'TOROS',
          icon: 'toros',
          iFrameUrls: {
            b2d: 'https://concesionarios-es.bmwgroup.com/toros/Search/Get',
            b2dIntranet: 'https://concesionarios-es.bmwgroup.net/toros/Search/Get'
          },
          webAgentSecuredPopupUrls: {
            b2d: 'https://concesionarios-es.bmwgroup.com/WENWebAgentLogin.html'
          },
          routingPath: 'toros',
          b2dRoles: ['es_b2b_srv_attendant', 'es_b2b_srv_advisor'],
          vinLongRequired: true
        },
        {
          titleResource: 'Neumáticos',
          icon: 'tire',
          iFrameUrls: {
            b2d: 'https://concesionarios-es.bmwgroup.com/tyres',
            b2dIntranet: 'https://concesionarios-es.bmwgroup.net/tyres'
          },
          webAgentSecuredPopupUrls: {
            b2d: 'https://concesionarios-es.bmwgroup.com/WENWebAgentLogin.html'
          },
          routingPath: 'neumaticos',
          b2dRoles: ['es_b2b_srv_attendant', 'es_b2b_srv_advisor']
        },
        {
          titleResource: 'SPT',
          icon: 'tire',
          iFrameUrls: {
            b2d: 'https://int.salesportaltires.com/login/s-gate/'
          },
          routingPath: 'spt',
          b2dRoles: [
            'spt_user',
            'spt_admin',
            'spt_order_creator',
            'spt_superuser',
            'spt_quote_admin',
            'spt_quote_creator',
            'spt_quote_user',
            'spt_availability_viewer'
          ]
        }
      ]
    },
    {
      titleResource: 'categories.support',
      apps: [
        {
          titleResource: 'apps.feedback',
          icon: 'thumb_up',
          webComponentUrls: {
            b2d: '/ui/feedback/main.js',
            b2dIntranet: '/ui/feedback/main.js',
            b2e: '/ui/feedback/main.js'
          },
          webComponentCustomTag: 'ueg-awp-feedback-frontend',
          routingPath: 'feedback',
          hideCaseBar: true
        },
        {
          titleResource: 'apps.ispiCockpit',
          icon: 'user',
          iFrameUrls: {
            b2d: 'https://ilz-user-test-wen.bmwgroup.com/ispi-cockpit/',
            b2dIntranet: 'https://ilz-user-test-wen.bmwgroup.net/ispi-cockpit/'
          },
          b2dRoles: [
            'ilean_admin_user',
            'ilean_as_leo_admin_user',
            'ilean_as_leo_admin_admin_inis',
            'ilean_as_leo_user',
            'ispi_compass_user'
          ],
          routingPath: 'ispicockpit',
          hideCaseBar: true
        },
        {
          titleResource: 'apps.feedbackAdmin',
          icon: 'calendar_car',
          webComponentUrls: {
            b2e: '/api/gui/feedbackadmin/main.js'
          },
          webComponentCustomTag: 'ueg-awp-feedback-admin-frontend',
          routingPath: 'feedbackadmin',
          hideCaseBar: true,
          b2eRoles: ['awp_b2e_feedbackadmin']
        },
        {
          titleResource: 'apps.marketConfiguration',
          icon: 'table_settings',
          webComponentUrls: {
            b2d: '/ui/leads/config/main.js',
            b2dIntranet: '/ui/leads/config/main.js'
          },
          webComponentCustomTag: 'leads-config-market',
          routingPath: 'marketconfiguration',
          hideCaseBar: true,
          b2dRoles: ['awp_b2d_marketadmin', 'awp_b2d_marketconfig']
        },
        {
          titleResource: 'apps.editingAudit',
          icon: 'table_search',
          webComponentUrls: {
            b2d: '/api/gui/leads/cockpit/main.js',
            b2dIntranet: '/api/gui/leads/cockpit/main.js'
          },
          webComponentCustomTag: 'leads-cockpit-editing-audit',
          routingPath: 'editingaudit',
          hideCaseBar: true,
          b2dRoles: ['awp_b2d_editing_audit']
        }
      ]
    }
  ],
  urls: {
    airServices: {
      b2d: 'https://air-services-b2d-qa2-wen-oidc.bmwgroup.com/ui',
      b2dIntranet: 'https://air-services-internal-qa2.bmwgroup.net/ui',
      b2e: 'https://air-services-internal-qa2.bmwgroup.net/ui'
    },
    keypool: {
      b2d: 'https://ckf-qa.bmwgroup.com',
      b2dIntranet: 'https://ckf-qa.bmwgroup.net'
    },
    mileageService: {
      b2d: 'https://ckf-int.bmwgroup.com',
      b2dIntranet: 'https://ckf-int.bmwgroup.net',
      b2e: 'https://ckf-int.bmwgroup.net'
    },
    servicePlanning: {
      b2d: 'https://ckf-int.bmwgroup.com',
      b2dIntranet: 'https://ckf-int.bmwgroup.net',
      b2e: 'https://ckf-int.bmwgroup.net'
    },
    checkControlMessage: {
      b2d: 'https://ckf-int.bmwgroup.com',
      b2dIntranet: 'https://ckf-int.bmwgroup.net',
      b2e: 'https://ckf-int.bmwgroup.net'
    },
    dealerCockpit: {
      b2d: 'https://dealercockpit2-e2e.bmwgroup.com',
      b2dIntranet: 'https://dealercockpit2-e2e.bmwgroup.net'
    },
    faultCodes: {
      b2d: 'https://ckf-int.bmwgroup.com',
      b2dIntranet: 'https://ckf-int.bmwgroup.net',
      b2e: 'https://ckf-int.bmwgroup.net'
    },
    cosy: {
      b2d: 'https://int.cosy.bmw.cloud/AWP/cosySec?',
      b2dIntranet: 'https://cosy-internal-int.bmwgroup.net/AWP/cosySec?',
      b2e: 'https://cosy-internal-int.bmwgroup.net/AWP/cosySec?'
    },
    etkConfig: {
      b2d: 'https://etk-cloud-eu-qa.bmwgroup.com',
      b2dIntranet: 'https://etk-cloud-eu-qa.bmwgroup.net',
      b2e: 'https://etk-cloud-eu-qa.bmwgroup.net'
    },
    los: {
      b2d: 'https://c2b-services-i.bmw.com',
      b2dIntranet: 'https://c2b-services-i.bmw.com'
    },
    printDataConfigurator: {
      b2d: 'https://www.core-apps-dev.aws.bmw.cloud/pdc-ui',
      b2dIntranet: 'https://www.core-dev.apps.4wm-scc-integration.eu-central-1.aws.cloud.bmw/pdc-ui'
    },
    updateService: {
      b2d: '/api/update/client/versions?hub=emea&realm=internet',
      b2dIntranet: '/api/update/client/versions?hub=emea&realm=intranet'
    },
    remoteKeyRead: {
      b2d: 'https://ckf-int.bmwgroup.com',
      b2dIntranet: 'https://ckf-int.bmwgroup.net'
    },
    carsServices: {
      b2d: 'https://catalog-research-int.aws.bmw.cloud',
      b2dIntranet: 'https://catalog-research-int.eu-central-1.aws.cloud.bmw',
      b2e: 'https://catalog-research-int.eu-central-1.aws.cloud.bmw'
    },
    esa: {
      b2d: 'https://esa1-us-e2e.bmwgroup.com/esa/#',
      b2dIntranet: 'https://esa1-us-e2e.bmwgroup.net/esa/#'
    },
    serviceCaseSessionManagementService: {
      b2d: 'https://api.scc-apps-int.aws.bmw.cloud/scclm',
      b2dIntranet: 'https://api.scc-apps-int.eu-central-1.aws.cloud.bmw/scclm'
    },
    sccAppointmentsService: {
      b2d: 'https://api.scc-apps-qa.aws.bmw.cloud/appt',
      b2dIntranet: 'https://api.scc-apps-qa.eu-central-1.aws.cloud.bmw/appt'
    },
    editMileage: {
      b2d: 'https://ckf-int.bmwgroup.com',
      b2dIntranet: 'https://ckf-int.bmwgroup.net',
      b2e: 'https://ckf-int.bmwgroup.net'
    },
    customerData: {
      b2d: 'https://cdf-test.rspretailcrm.com',
      b2dIntranet: 'https://cdf-test.ext.eu-central-1.aws.cloud.bmw'
    }
  }
};
