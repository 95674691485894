import {Component, Input} from '@angular/core';
import {VehicleSearchOverlayTab} from './vehicle-search-overlay-tab';

@Component({
  selector: 'app-vehicle-search-overlay-tab',
  templateUrl: './vehicle-search-overlay-tab.component.html',
  styleUrls: ['./vehicle-search-overlay-tab.component.scss']
})
export class VehicleSearchOverlayTabComponent {

  @Input()
  selectedTab: VehicleSearchOverlayTab;
}
