import {Injectable} from '@angular/core';
import {Brand} from '@service-and-repairs/awpintegrationlib';
import {ConfigurationLoader} from '../core/configuration/services/configuration.loader';
import {UserData} from '../core/user/models/user-data';
import {UserService} from '../core/user/services/user.service';
import {ThinClientModeService} from '../services/thin-client-mode.service';

@Injectable({
  providedIn: 'root'
})
export class AirParamService {
  constructor(private readonly configurationLoader: ConfigurationLoader,
              private readonly userService: UserService) {
  }

  static convertMileageUnitForAir(awpMileageUnit: string): string {
    switch (awpMileageUnit) {
      case 'km':
        return 'K';
      case 'mi':
        return 'M';
      default:
        return 'K';
    }
  }

  static getAirBrands(user: UserData): string {
    const brands: Brand[] = user?.getSelectedBrands();
    if (brands === null || brands === undefined) {
      return '';
    }
    return brands
      .filter(b => b && b !== Brand.UNKNOWN)
      .map(AirParamService.toAirBrand)
      .join(',');
  }

  private static addParamIfAbsent(params: any, key: string, value: string | boolean): void {
    if (!params[key]) {
      Object.assign(params, {[key]: value});
    }
  }

  private static toAirBrand(brand: Brand): string {
    switch (brand) {
      case Brand.BMW:
        return 'BMW';
      case Brand.MINI:
        return 'MINI';
      case Brand.MOT:
        return 'BMW MOTORRAD';
      case Brand.BMW_i:
        return 'BMWi';
      case Brand.RR:
        return 'ROLLS ROYCE';
      case Brand.C_1:
        return 'C_1';
      case Brand.ZINORO:
        return 'ZINORO';
      case Brand.TOYOTA:
        return 'TOYOTA';
      case Brand.HUSQVARNA:
        return 'HUSQVARNA';
      case Brand.UNKNOWN:
        throw new Error('Brand.UNKNOWN cannot be mapped to an AIR brand');
    }
  }

  private static getAirMileageUnit(user: UserData): string {
    if (user?.getMileageUnit() === 'mi') {
      return 'MILE';
    } else {
      return 'KILOMETER';
    }
  }

  private static getAirRoles(isDmsReadAccessAllowed: boolean,
                             isDmsWriteAccessAllowed: boolean,
                             isDmsLicensePlateSearchAllowed: boolean): string {
    const airRoles: string[] = [];
    if (isDmsWriteAccessAllowed) {
      airRoles.push('R32');
    }
    if (isDmsReadAccessAllowed) {
      airRoles.push('R33');
    }
    if (isDmsLicensePlateSearchAllowed) {
      airRoles.push('R36');
    }

    return airRoles.join(',');
  }

  addMandatoryAirParams(airParams: any): void {
    const user: UserData = this.userService.userSubject.getValue();
    AirParamService.addParamIfAbsent(airParams, 'LANGUAGE', user?.getLocale());
    AirParamService.addParamIfAbsent(airParams, 'CALLING_APPLICATION', 'AFTERSALES_WORKPLACE');
    AirParamService.addParamIfAbsent(airParams, 'DISTANCE_UNIT', AirParamService.getAirMileageUnit(user));
    if (ThinClientModeService.thinClientMode) {
      AirParamService.addParamIfAbsent(airParams, 'PROFILE', 'AWPCLIENT');
    } else {
      AirParamService.addParamIfAbsent(airParams, 'PROFILE', 'AWP');
    }
    if (user?.isB2E()) {
      AirParamService.addParamIfAbsent(airParams, 'BRANDS', AirParamService.getAirBrands(user));
      AirParamService.addParamIfAbsent(airParams, 'COUNTRY', user.getSelectedCountry());
    } else if (user) {
      AirParamService.addParamIfAbsent(
        airParams,
        'VPNR',
        user.getBusinessPartner()?.getDistributionPartnerNumber()
      );
      AirParamService.addParamIfAbsent(airParams, 'OUTLET', user.getBusinessPartner()?.getOutletNumber());
      this.applyOutletConfigToAirParams(airParams);
    }
  }

  private applyOutletConfigToAirParams(airParams: any): void {
    AirParamService.addParamIfAbsent(airParams, 'ALLOW_FRU_VALUE', this.configurationLoader.showFlatRateUnitValues());
    AirParamService.addParamIfAbsent(airParams, 'ALLOW_GDPR', this.configurationLoader.isGdprEnabled());
    AirParamService.addParamIfAbsent(
      airParams,
      'ALLOW_SERVICE_HISTORY_DELETION',
      this.configurationLoader.isServiceHistoryDeletionAllowed()
    );
    if (ThinClientModeService.thinClientMode) {
      AirParamService.addParamIfAbsent(airParams, 'ROLES', AirParamService.getAirRoles(
        this.configurationLoader.isDmsReadAccessAllowed(),
        this.configurationLoader.isDmsWriteAccessAllowed(),
        this.configurationLoader.isDmsLicensePlateSearchAllowed()
      ));
    }
  }
}
