import {Component, Input, OnInit} from '@angular/core';
import {
  ServiceCaseSessionReference
} from '../../../../core/service-case-session/interfaces/service-case-session.reference';
import {ServiceCaseSession} from '../../../../core/service-case-session/interfaces/service-case.session';
import {UserData} from '../../../../core/user/models/user-data';
import {UserService} from '../../../../core/user/services/user.service';

@Component({
  selector: 'app-service-case-session-selection',
  templateUrl: './service-case-session-selection.component.html',
  styleUrls: ['./shared-styles.scss']
})
export class ServiceCaseSessionSelectionComponent implements OnInit {
  @Input()
  sessions: ServiceCaseSession[] = [];

  locale: string;

  private selectedCallback: (session: ServiceCaseSession) => void;
  private cancelCallback: () => void;

  constructor(private readonly userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.userSubject.subscribe((user: UserData) => this.locale = user?.getLocale());
  }

  show(sessions: ServiceCaseSession[]): Promise<ServiceCaseSession> {
    this.sessions = sessions;
    return new Promise<ServiceCaseSession>((resolve, reject): void => {
      this.selectedCallback = resolve;
      this.cancelCallback = reject;
    });
  }

  sessionSelected(session: ServiceCaseSession): void {
    this.sessions = [];
    session.references?.map((r: ServiceCaseSessionReference) => r.source);
    this.selectedCallback(session);
  }

  getSources(session: ServiceCaseSession): string {
    return session.references?.map((reference: ServiceCaseSessionReference) => reference.source).join(',');
  }

  close(): void {
    this.sessions = [];
    this.cancelCallback();
  }
}
