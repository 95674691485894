import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IServerApi, VehicleType} from '@service-and-repairs/awpintegrationlib';
import {ReplaySubject} from 'rxjs';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {EtkConfiguration} from '../interfaces/etk-configuration';
import {LeadsConfiguration} from '../interfaces/leads-configuration';
import {OutletRelatedConfiguration} from '../interfaces/outlet-related-configuration';
import {ConfigurationService} from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationLoader {
  configChanged: ReplaySubject<{
    outletRelatedConfig: OutletRelatedConfiguration,
    etkConfig: EtkConfiguration
  }> = new ReplaySubject<{outletRelatedConfig: OutletRelatedConfiguration, etkConfig: EtkConfiguration}>(1);
  private outletRelatedConfiguration: OutletRelatedConfiguration;

  private readonly awpLib: IServerApi;
  private businessPartnerId: string;
  private vehicleType: VehicleType;
  private user: UserData;

  constructor(awpClientLibService: AwpClientLibService,
              private readonly configurationService: ConfigurationService,
              private readonly translate: TranslateService,
              private readonly userService: UserService) {
    this.awpLib = awpClientLibService.serverApi;
    userService.userSubject.subscribe((user: UserData): void => {
      this.user = user;
      this.reloadConfig(false);
    });
  }

  showFlatRateUnitValues(): boolean {
    return this.user?.isB2E()
      || this.outletRelatedConfiguration?.outletConfiguration?.flatrateValuesAllowed
      || this.hasUserAccessToFlatRateUnits(
        this.user,
        this.outletRelatedConfiguration?.outletConfiguration?.usersWithFlatrateValueAccess
      );
  }

  isFlatRateUnitAmountZeroAllowed(): boolean {
    return this.user?.isB2E() || this.outletRelatedConfiguration?.outletConfiguration?.isFlatRateUnitAmountZeroAllowed;
  }

  isServiceHistoryDeletionAllowed(): boolean {
    return this.user?.isB2E()
      || this.outletRelatedConfiguration?.outletConfiguration?.serviceHistoryEntryDeletionAllowed;
  }

  isGdprEnabled(): boolean {
    return this.user?.isB2E() || this.outletRelatedConfiguration?.outletConfiguration?.airGdprReportAllowed;
  }

  isDmsReadAccessAllowed(): boolean {
    return this.user
      && !this.user.isB2E()
      && this.outletRelatedConfiguration?.outletConfiguration?.dmsReadAccessAllowed;
  }

  isDmsWriteAccessAllowed(): boolean {
    return this.user
      && !this.user.isB2E()
      && this.outletRelatedConfiguration?.outletConfiguration?.dmsWriteAccessAllowed;
  }

  isDmsLicensePlateSearchAllowed(): boolean {
    return this.user
      && !this.user.isB2E()
      && this.outletRelatedConfiguration?.outletConfiguration?.airNumberPlateSearchAllowed;
  }

  getSpecialistGroups(): string[] {
    return this.outletRelatedConfiguration?.outletConfiguration?.specialistGroups ?? [];
  }

  getLeadsDealerConfig(): LeadsConfiguration {
    return this.outletRelatedConfiguration?.leadsDealerConfiguration;
  }

  private hasUserAccessToFlatRateUnits(user: UserData, users: string[]): boolean {
    return users?.some((name: string): boolean => name.toLowerCase() === user.getName().toLowerCase())
      || users?.some((loginName: string): boolean => loginName.toLowerCase() === user.getLoginName().toLowerCase());
  }

  reloadConfig(force: boolean): void {
    if ((force || this.mustReloadConfig()) && this.canReloadConfig()) {
      this.businessPartnerId = this.user?.getBusinessPartner()?.getBusinessPartnerId();
      this.vehicleType = this.user.getSelectedVehicleType();
      Promise.all([this.getOutletConfig(), this.getEtkConfig()])
        .then(([outletRelatedConfig, etkConfig]) => this.onConfigChanged(outletRelatedConfig, etkConfig));
    }
  }

  private onConfigChanged(outletRelatedConfig: OutletRelatedConfiguration, etkConfig: EtkConfiguration): void {
    if (outletRelatedConfig || etkConfig) {
      this.outletRelatedConfiguration = outletRelatedConfig;
      this.configChanged.next({
        outletRelatedConfig,
        etkConfig
      });
    }
  }

  private mustReloadConfig(): boolean {
    return this.user?.getBusinessPartner()?.getBusinessPartnerId() !== this.businessPartnerId
      || this.user?.getSelectedVehicleType() !== this.vehicleType;
  }

  private canReloadConfig(): boolean {
    return this.user?.getBusinessPartner()?.getBusinessPartnerId() != undefined;
  }

  private getOutletConfig(): Promise<OutletRelatedConfiguration> {
    return new Promise(resolve => {
      this.configurationService.getOutletConfigFromBackend(this.businessPartnerId, this.vehicleType)
        .then((outletRelatedConfiguration: OutletRelatedConfiguration) => resolve(outletRelatedConfiguration))
        .catch((): void => {
          this.showErrorMessageOnceTranslationExists('config.loadError');
          resolve(undefined);
        });
    });
  }

  private getEtkConfig(): Promise<EtkConfiguration> {
    if (!this.userService.appVisibleForUser('etk')) {
      return Promise.resolve(undefined);
    }

    return new Promise(resolve => {
      this.configurationService.getEtkConfigFromBackend(
        this.user.getContext(),
        this.user.getBusinessPartner().getBusinessPartnerId(),
        this.user.getSelectedVehicleType()
      )
        .then((etkConfig: EtkConfiguration) => resolve(etkConfig))
        .catch((): void => {
          this.showErrorMessageOnceTranslationExists('config.loadErrorETK');
          resolve(undefined);
        });
    });
  }

  private showErrorMessageOnceTranslationExists(key: string): void {
    this.translate.get(key).subscribe((translation: string) => this.awpLib.notifyError(translation));
  }
}
