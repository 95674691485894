import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {VehicleType} from '@service-and-repairs/awpintegrationlib';
import {AuthInfo} from '../../auth/auth-info';
import {AuthInfoFactory} from '../../auth/auth-info-factory';
import {AuthenticationTokenBearerService} from '../../auth/authentication-token-bearer.service';
import {ConfigurationLoader} from '../../core/configuration/services/configuration.loader';
import {ConfigurationService} from '../../core/configuration/services/configuration.service';
import {UserService} from '../../core/user/services/user.service';
import {NotificationsService} from '../../services/notifications.service';

@Component({
  selector: 'app-configuration-overlay',
  templateUrl: './configuration-overlay.component.html',
  styleUrls: ['./configuration-overlay.component.scss']
})
export class ConfigurationOverlayComponent implements OnInit {
  @Input()
  viewState: any;

  businessPartnerId: string;
  businessNumber: string;
  distributionPartnerNumber: string;
  outletNumber: string;

  @ViewChild('uegAwpConfigurationWebComponent', {read: ElementRef})
  uegAwpConfigurationWebComponent: ElementRef;

  locale: string;
  hub: string;
  etkConfigurationBaseUrl: string;
  printConfigurationBaseUrl: string;
  vehicleType: VehicleType;
  brands: string;
  authHeaders: string;
  authInfo: string;

  constructor(private userService: UserService,
              private notificationsService: NotificationsService,
              private translate: TranslateService,
              private configurationLoader: ConfigurationLoader,
              private authenticationTokenBearerService: AuthenticationTokenBearerService) {
    this.hub = environment.hub;

    this.userService.userSubject.subscribe(() => this.setUserData());

    this.onAuthTokenChanged();
    this.authenticationTokenBearerService.oidcTokenSubject.subscribe(this.onAuthTokenChanged.bind(this));
    this.authenticationTokenBearerService.csslTokenSubject.subscribe(this.onAuthTokenChanged.bind(this));
  }

  private static appendScriptToDocumentHead(script: HTMLScriptElement): void {
    document.head.appendChild(script);
  }

  ngOnInit(): void {
    this.loadWebComponent('/ui/configuration/main.js')
      .then(() => this.uegAwpConfigurationWebComponent.nativeElement.api.show())
      .catch(() => this.notificationsService.showErrorNotification(this.translate.instant('config.loadError')));
  }

  onConfigChange(): void {
    this.notificationsService.showSuccessNotification(this.translate.instant('config.saveSuccess'));
    this.configurationLoader.reloadConfig(true);
  }

  private userHasRole(...roles: string[]): boolean {
    const user = this.userService.userSubject.getValue();
    return roles.every(role => user.getRoles().includes(role));
  }

  private isAppIntegrated(path: string): boolean {
    return environment.categories
      .some(category => category.apps
        .some(app => app.routingPath === path)
      );
  }

  userIsAbleToSeeEtkInAWP(): boolean {
    return this.isAppIntegrated('etk') && this.userHasRole('awp_b2d_etk', 'etk_b2d_admin');
  }

  userIsAbleToDownloadThinClient(): boolean {
    // TODO: check market config for Thin Client markets
    const isThinClientHub: boolean = environment.hub !== 'CN';

    const userIsAdmin: boolean = this.userService.userIsDealerAdmin();
    return isThinClientHub && userIsAdmin;
  }

  userIsAbleToSeePrintInAWP(): boolean {
    return this.userHasRole('awp_b2d_printcenter');
  }

  userIsAbleToSeeLeadsInAWP(): boolean {
    return this.userHasRole('awp_b2d_leads');
  }

  private loadWebComponent(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.head.querySelectorAll(`script[src="${src}"]`).length === 0) {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          script.parentElement?.removeChild(script);
          reject('ConfigurationOverlayComponent script error');
        };
        ConfigurationOverlayComponent.appendScriptToDocumentHead(script);
      } else {
        resolve();
      }
    });
  }

  private setUserData(): void {
    const user = this.userService.userSubject.getValue();
    this.businessPartnerId = user.getBusinessPartner()?.getBusinessPartnerId();
    this.businessNumber = user.getBusinessNumber();
    this.distributionPartnerNumber = user.getBusinessPartner()?.getDistributionPartnerNumber();
    this.outletNumber = user.getBusinessPartner()?.getOutletNumber();
    this.vehicleType = user.getSelectedVehicleType();
    this.brands = user.getBrandsOfSelectedBusinessPartner().map(brand => brand.valueOf()).join(',');
    this.locale = user?.getLocale();
    this.etkConfigurationBaseUrl = ConfigurationService.getConfigBaseUrl('etkConfig', user?.getContext());
    this.printConfigurationBaseUrl = ConfigurationService.getConfigBaseUrl(
      'printDataConfigurator',
      user?.getContext()
    );
  }

  private onAuthTokenChanged(): void {
    const userData = this.userService.userSubject.getValue();
    if (userData) {
      this.authInfo = JSON.stringify(AuthInfoFactory.makeAuthInfo(userData));
    } else {
      this.authInfo = JSON.stringify(new AuthInfo());
    }

    const oidcToken = this.authenticationTokenBearerService.oidcTokenSubject.getValue();
    if (oidcToken.access_token) {
      this.authHeaders = JSON.stringify(oidcToken.getHeaders());
    } else {
      this.authHeaders = '{}';
    }
  }
}
