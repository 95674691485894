import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {OidcConfigProvider} from '../../oidc/OidcConfigProvider';
import {UserData} from '../core/user/models/user-data';
import {AwpClientLibService} from '../services/awp-client-lib.service';
import {Util} from '../util/util';
import {AuthInfoFactory} from './auth-info-factory';
import {CsslToken} from './cssl/cssl-token';
import {OidcToken} from './oidc/oidc-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenBearerService {
  private hostname = window.location.hostname;
  public readonly oidcTokenSubject: BehaviorSubject<OidcToken>;
  public readonly csslTokenSubject: BehaviorSubject<CsslToken>;
  private csslTokenRefreshTimer: number;
  private csslTokenRefreshTimeoutMs = 2000;

  constructor(libService: AwpClientLibService,
              private httpClient: HttpClient) {
    const businessContext = (new OidcConfigProvider()).get(this.hostname).businessContext;

    // In case tokens already exist
    this.oidcTokenSubject = new BehaviorSubject(OidcToken.readFromStorage(businessContext));
    this.csslTokenSubject = new BehaviorSubject(CsslToken.readFromStorage(businessContext));

    // Wait for tokens to be set initially or refreshed
    window.addEventListener('oidcTokenRefreshed', () => {
      const token = OidcToken.readFromStorage(businessContext);
      this.oidcTokenSubject.next(token);
      libService.serverApi.publishOidcToken(token.toLibToken());
    });
  }

  fetchAndStoreCsslToken(userData: UserData): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getCsslTokenFromBackend(userData)
        .then(csslToken => {
          this.publishCsslToken(csslToken);
          this.scheduleTimerForCsslTokenRefresh(userData);
          resolve();
        })
        .catch(errorMessage => {
          this.publishCsslToken(null);
          reject(errorMessage);
        });
    });
  }

  private getCsslTokenFromBackend(userData: UserData): Promise<CsslToken> {
    console.info(this.logPrefix() + 'Requesting CSSL token from backend.');
    return new Promise<CsslToken>((resolve, reject) => {
      const headers: HttpHeaders = this.getCsslHttpHeaders(userData);
      // Retrieve a CSSL token; request will contain current WEN authentication via WenInterceptor
      this.httpGetCsslToken(headers).subscribe({
        next: (response: any) => {
          const csslToken = CsslToken.create(response.data, userData.getContext());
          if (csslToken) {
            console.info(this.logPrefix() + 'Successfully retrieved CSSL token from backend.');
            resolve(csslToken);
          } else {
            const errorMessage = `Failed to retrieve CSSL token from backend.`;
            console.error(this.logPrefix() + errorMessage);
            reject(errorMessage);
          }
        },
        error: (response) => {
          const errorMessage = `Failed to retrieve CSSL token from backend. Response status: ${response.status}.`;
          console.error(this.logPrefix() + errorMessage);
          reject(errorMessage);
        }
      });
    });
  }

  private publishCsslToken(csslToken: CsslToken): void {
    csslToken?.writeToStorage();
    this.csslTokenSubject.next(csslToken);
  }

  private scheduleTimerForCsslTokenRefresh(userData: UserData): void {
    if (this.csslTokenRefreshTimer) {
      clearInterval(this.csslTokenRefreshTimer);
    }
    this.csslTokenRefreshTimer = setInterval(() => {
      this.refreshCsslTokenIfExpired(userData);
    }, this.csslTokenRefreshTimeoutMs);
  }

  private refreshCsslTokenIfExpired(userData: UserData): void {
    const csslToken = CsslToken.readFromStorage(userData.getContext());
    if (!csslToken.csslToken) {
      console.warn(this.logPrefix() + 'Token refresh found no CSSL token in local storage. Is CSSL still alive?');
    }
    if (!csslToken.csslToken || csslToken.isExpired()) {
      this.getCsslTokenFromBackend(userData)
        .then(csslToken => {
            this.resetCsslTokenRefreshTimeout(userData);
            this.publishCsslToken(csslToken);
          }
        )
        .catch(error => {
          console.error(this.logPrefix() +
            `CSSL token refresh failed. Error status: ${error.statusText}. Removing any expired token from storage.`);
          this.increaseCsslTokenRefreshTimeout(userData);
          this.publishCsslToken(null);
          csslToken.deleteFromStorage();
        });
    } else if (environment.deploymentEnvironment === 'DEV_LOCAL' || environment.deploymentEnvironment === 'DEV_TEST') {
      console.debug(this.logPrefix() + 'CSSL token is still fresh.');
    }
  }

  private resetCsslTokenRefreshTimeout(userData: UserData): void {
    this.applyCsslTokenRefreshTimeout(2000, userData);
  }

  private increaseCsslTokenRefreshTimeout(userData: UserData): void {
    let timeout = 60000;
    if (this.csslTokenRefreshTimeoutMs < 15000) {
      timeout = 15000;
    } else if (this.csslTokenRefreshTimeoutMs < 30000) {
      timeout = 30000;
    }
    this.applyCsslTokenRefreshTimeout(timeout, userData);
  }

  private applyCsslTokenRefreshTimeout(timeoutMs: number, userData: UserData): void {
    if (this.csslTokenRefreshTimeoutMs != timeoutMs) {
      this.csslTokenRefreshTimeoutMs = timeoutMs;
      console.info(this.logPrefix() + `Setting CSSL refresh timeout to ${this.csslTokenRefreshTimeoutMs}ms.`);
      this.scheduleTimerForCsslTokenRefresh(userData);
    }
  }

  private httpGetCsslToken(headers: HttpHeaders): Observable<any> {
    return this.httpClient.get('/api/token/cssl-jwt/v1', {headers});
  }

  getCsslHttpHeaders(user: UserData): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Auth-App-Id', 'awp');
    headers = headers.set('Auth-Cache-Control', 'no-cache'); // Tell gateway not to return a cached but a fresh token
    if (user.isB2E()) {
      headers = headers.set('Auth-Brands', AuthInfoFactory.awpBrandsToCsslBrands(user.getSelectedBrands()));
      headers = headers.set('Auth-Country', user.getSelectedCountry());
    } else {
      headers = headers.set('Auth-Dist-Partner', user.getBusinessPartner().getDistributionPartnerNumber());
      headers = headers.set('Auth-Outlet', user.getBusinessPartner().getOutletNumber());
    }
    return headers;
  }

  private logPrefix(): string {
    return Util.logPrefix('Webapp', 'Auth');
  }
}
