import {Injectable} from '@angular/core';
import {AuthenticationTokenBearerService} from '../auth/authentication-token-bearer.service';
import {ServiceCaseHolder} from '../core/service-case/models/service-case-holder';
import {UserData} from '../core/user/models/user-data';
import {UserService} from '../core/user/services/user.service';
import {IIframeAppConfiguration} from "../interfaces/IIframeAppConfiguration";
import {AirParamService} from './air-param.service';

/**
 * Service to provide path and query parameters for the URL of iframe apps.
 */
@Injectable({
  providedIn: 'root'
})
export class IframeParamService {
  user: UserData;

  constructor(private serviceCaseHolder: ServiceCaseHolder,
              userService: UserService,
              private airParamService: AirParamService,
              private authenticationTokenBearerService: AuthenticationTokenBearerService) {
    userService.userSubject.subscribe((user: UserData) => this.user = user);
  }

  /**
   * Path parameters for iframe urls have to be set here.
   * Will be overwritten if a client passes a path parameter to navigateTo in the AWP Client Lib.
   *
   * @param app The app shown in the iframe.
   */
  static getPathParamForApp(app: IIframeAppConfiguration): string {
    if (app.routingPath === 'air') {
      return 'fork';
    }
    return '';
  }

  /**
   * Any parameters for an iframe have to be set here.
   *
   * @param app The app shown in the iframe.
   */
  getQueryParamsForApp(app: IIframeAppConfiguration): object {
    const locale = this.user?.getLocale();

    switch (app.routingPath) {
      case 'dealercockpit':
        return this.getDealerCockpitParams();
      case 'etk':
        return {caller: 'AWP'};
      case 'air':
        return this.getAirParams();
      case 'ispicockpit':
        return {
          language: locale.substring(0, 2),
          caller: 'awp'
        };
      case 'wvi':
        return this.getWviParams();
      case 'partsordering':
      case 'orderoverview':
      case 'partsinfo':
      case 'partsorderdocuments':
        return {
          caller: 'awp'
        };
      case 'voiceofcustomer':
        return {
          VIN: this.serviceCaseHolder.getActiveCase()?.getVinLong()
        };
      case 'toros':
        return {
          display: 'MINIMAL',
          Vin: this.serviceCaseHolder.getActiveCase().getVinLong()
        };
      default:
        return {};
    }
  }

  private getDealerCockpitParams(): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const params = {
      LANG_ISO: this.user?.getLocale().substring(0, 2).toLowerCase(),
      COUNTRY_ISO: this.user?.getB2XCountryCode().toLowerCase(),
      applicationIndicator: 'AWP'
    };
    const vin = serviceCase?.getVinLong() ?? serviceCase?.getVinShort();
    if (vin) {
      Object.assign(params, {VIN17: vin});
      Object.assign(params, {BRAND: serviceCase?.getBrand()});
    }
    if (this.user?.getContext() !== 'B2E') {
      Object.assign(params, {
        USER_ID: this.user?.getBusinessPartner()?.getDistributionPartnerNumber() +
          '_' +
          this.user?.getBusinessPartner()?.getOutletNumber()
      });
    }
    return params;
  }

  private getWviParams(): object {
    const accessToken = this.authenticationTokenBearerService.oidcTokenSubject.getValue().access_token;
    const businessContext = this.user?.getContext();
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    return {
      level: 'LVL01',
      vehicleInfoCB: 'on',
      customerInfoCB: 'on',
      optionInfoCB: 'on',
      inReviewClaimInfoCB: 'on',
      claimInfoCB: 'on',
      refusedClaimInfoCB: 'on',
      correctionClaimInfoCB: 'on',
      mysteryShopperClaimInfoCB: 'on',
      vehicleCommentCB: 'on',
      contractInfoCB: 'on',
      eligibleContractCB: 'on',
      campaignInfoCB: 'on',
      damageDisclosureInfoCB: 'on',
      chassisNumber: serviceCase?.getVinLong(),
      ChassisNumbers: serviceCase?.getVinLong(),
      token: accessToken,
      businessContext: businessContext,
      vinContext: 'N'
    };
  }

  private getAirParams(): object {
    const serviceCase = this.serviceCaseHolder.getActiveCase();
    const params = {};
    this.airParamService.addMandatoryAirParams(params);
    if (serviceCase?.getVinLong()) {
      Object.assign(params, {VIN: serviceCase?.getVinLong()});
    } else if (serviceCase?.getVinShort()) {
      Object.assign(params, {VIN: serviceCase?.getVinShort()});
    } else if (serviceCase?.getTypeCode()) {
      Object.assign(params, {TYPE_CODE: serviceCase?.getTypeCode()});
    }
    return params;
  }
}
