import {formatDate} from '@angular/common';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActorId, IServerApi, IVehicle} from '@service-and-repairs/awpintegrationlib';
import {Subject} from 'rxjs';
import {DmsVehicle} from '../../../components/dms-search/dms-vehicle';
import {DmsService} from '../../../components/dms-search/dms.service';
import {OrderAndAppointmentSearchResult} from '../../../components/dms-search/order-and-appointment-search-result';
import {
  CaseSelectionOverlayComponent
} from '../../../components/service-case-bar/case/selection/case-selection-overlay.component';
import {
  ServiceCaseSessionSelectionComponent
} from '../../../components/service-case-bar/case/selection/service-case-session-selection.component';
import {
  VehicleSelectionOverlayComponent
} from '../../../components/vehicle/vehicle-selection-overlay/vehicle-selection-overlay.component';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {AnalyticsAction} from '../../analytics/enums/analytics-action';
import {VehicleSearchOrigin} from '../../analytics/enums/vehicle-search-origin';
import {AnalyticsService} from '../../analytics/services/analytics.service';
import {ServiceCaseSession} from '../../service-case-session/interfaces/service-case.session';
import {ServiceCaseSessionLoader} from '../../service-case-session/services/service-case-session-loader';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {VehicleDataLoader} from '../../vehicle/services/vehicle-data.loader';
import {VehicleLoader} from '../../vehicle/services/vehicle.loader';
import {ServiceCaseLoader} from '../services/service-case.loader';
import {ServiceCase} from './service-case';
import {ServiceCaseHolder} from './service-case-holder';
import {ServiceCaseUpdater} from './service-case-updater';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseManager {

  private readonly awpLib: IServerApi;

  private caseSelectionOverlay: CaseSelectionOverlayComponent;

  private sessionSelectionOverlay: ServiceCaseSessionSelectionComponent;

  caseLoadingStarted: Subject<void> = new Subject<void>();

  caseLoadingFinished: Subject<void> = new Subject<void>();

  constructor(awpClientLibService: AwpClientLibService,
              private readonly analyticsService: AnalyticsService,
              private readonly dmsService: DmsService,
              private readonly serviceCaseHolder: ServiceCaseHolder,
              private readonly serviceCaseLoader: ServiceCaseLoader,
              private readonly serviceCaseUpdater: ServiceCaseUpdater,
              private readonly serviceCaseSessionLoader: ServiceCaseSessionLoader,
              private readonly translate: TranslateService,
              private readonly userService: UserService,
              private readonly vehicleLoader: VehicleLoader,
              private readonly vehicleDataLoader: VehicleDataLoader) {
    this.awpLib = awpClientLibService.serverApi;

    this.translate.onLangChange.subscribe(this.onTranslationChange.bind(this));

    this.subscribeToRequestToOpenServiceCaseByVin();
  }

  setSelectionOverlays(vehicleSelectionOverlay: VehicleSelectionOverlayComponent,
                       caseSelectionOverlay: CaseSelectionOverlayComponent,
                       sessionSelectionOverlay: ServiceCaseSessionSelectionComponent): void {
    this.vehicleLoader.vehicleSelectionOverlay = vehicleSelectionOverlay;
    this.caseSelectionOverlay = caseSelectionOverlay;
    this.sessionSelectionOverlay = sessionSelectionOverlay;
  }

  // region create cases

  openNewServiceCaseWithoutVehicle(): void {
    this.createAndOpenServiceCase(
      ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue()),
      VehicleSearchOrigin.NO_VEHICLE
    );
  }

  openNewServiceCaseWithVehicle(vehicle: IVehicle): void {
    const serviceCase: ServiceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    serviceCase.updateVehicle(vehicle);
    this.createAndOpenServiceCase(serviceCase, VehicleSearchOrigin.TYPE_ATTRIBUTES);
  }

  openNewServiceCaseByTypeCode(typeCode: string): void {
    const serviceCase: ServiceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    serviceCase.setTypeCode(typeCode.toUpperCase());
    this.createAndOpenServiceCase(serviceCase, VehicleSearchOrigin.TYPE_CODE);
  }

  private openNewServiceCaseByVin(vin: string, origin: string): void {
    const serviceCase: ServiceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    vin = vin.toUpperCase();
    serviceCase.setVinLong(vin.length === 17 ? vin : null);
    serviceCase.setVinShort(vin.slice(vin.length - 7));
    this.createAndOpenServiceCase(serviceCase, origin);
  }

  private openNewServiceCaseByDmsVehicle(dmsVehicle: DmsVehicle, origin: string): void {
    const serviceCase: ServiceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    serviceCase.setDmsOrderNumber(dmsVehicle.orderNumber);
    serviceCase.setCustomerName(dmsVehicle.customerName);
    serviceCase.setDmsCustomerNumber(dmsVehicle.customerId);
    const vin: string = dmsVehicle.vin.toUpperCase();
    serviceCase.setVinLong(vin.length === 17 ? vin : null);
    serviceCase.setVinShort(vin.slice(vin.length - 7));
    serviceCase.setLicensePlate(dmsVehicle.licensePlate);
    this.createAndOpenServiceCase(serviceCase, origin);
  }

  private createAndOpenServiceCase(serviceCase: ServiceCase, origin: string): void {
    this.caseLoadingStarted.next();
    this.fetchServiceCaseSession(serviceCase.getVinLong())
      .then((session: ServiceCaseSession): void => {
        if (session) {
          serviceCase.setServiceCaseSession(session);
        }
        this.serviceCaseLoader.createServiceCase(serviceCase)
          .then((createdServiceCase: ServiceCase): void => {
            // Frontend timestamp can be manipulated. Therefore, we reset the default title of the service case
            // according to the actual timestamp set in the backend with respect to the user's locale.
            createdServiceCase.setTitle(formatDate(
              createdServiceCase.getCreatedAt(),
              'short',
              this.userService.userSubject.getValue().getLocale()
            ));
            this.vehicleLoader.loadVehicleInServiceCase(createdServiceCase)
              .then((mustSaveServiceCase: boolean): void => {
                if (mustSaveServiceCase || createdServiceCase.getSccId()) {
                  this.serviceCaseUpdater.saveServiceCaseMetadata(createdServiceCase);
                }
                this.openServiceCase(createdServiceCase, true);
                this.analyticsService.postServiceCaseCrudEvent(
                  AnalyticsAction.CREATE_IN_NEW_TAB,
                  origin,
                  createdServiceCase
                );
              });
          })
          .catch((reason: Error) => this.handleServiceCaseOpenError(reason));
      })
      .catch((): void => {
        // do nothing
      });
  }

  // endregion create service cases

  // region load service cases

  openExistingServiceCaseById(externalId: string, origin: string): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.caseLoadingStarted.next();
      this.serviceCaseLoader.getServiceCaseById(externalId)
        .then((serviceCase: ServiceCase): void => {
          this.vehicleLoader.loadVehicleInServiceCase(serviceCase)
            .then((mustSaveServiceCase: boolean): void => {
              if (mustSaveServiceCase) {
                this.serviceCaseUpdater.saveServiceCaseMetadata(serviceCase);
              }
              this.openServiceCase(serviceCase, true);
              this.analyticsService.postServiceCaseCrudEvent(AnalyticsAction.OPEN_IN_NEW_TAB, origin, serviceCase);
              resolve();
            });
        })
        .catch((reason: Error): void => {
          this.handleServiceCaseOpenError(reason);
          reject(reason);
        });
    });
  }

  reloadServiceCaseById(externalId: string, setActive: boolean): Promise<void> {
    return new Promise(resolve => {
      this.caseLoadingStarted.next();
      this.serviceCaseLoader.getServiceCaseById(externalId)
        .then((serviceCase: ServiceCase): void => {
          this.vehicleLoader.loadVehicleInServiceCase(serviceCase)
            .then((mustSaveServiceCase: boolean): void => {
              if (mustSaveServiceCase) {
                this.serviceCaseUpdater.saveServiceCaseMetadata(serviceCase);
              }
              this.openServiceCase(serviceCase, false);
            });
        })
        .catch(reason => this.handleServiceCaseReloadError(reason, externalId, setActive))
        .finally(resolve);
    });
  }

  private handleServiceCaseReloadError(reason: Error, externalId: string, setActive: boolean): void {
    this.caseLoadingFinished.next();
    const serviceCase: ServiceCase = ServiceCase.constructMinimalServiceCase(this.userService.userSubject.getValue());
    serviceCase.setExternalId(externalId);
    serviceCase.loadingError = reason.message;
    this.serviceCaseHolder.openServiceCaseOrSetActive(serviceCase, setActive);
  }

  selectAndOpenServiceCaseByVin(vin: string, origin: string): void {
    this.caseLoadingStarted.next();
    const user: UserData = this.userService.userSubject.getValue();
    const checkForExistingCases: boolean = !user.getNewCaseAfterVehicleIdentification();
    if (checkForExistingCases) {
      Promise
        .all([this.serviceCaseLoader.getServiceCasesByVin(vin), this.searchOrdersAndAppointmentsViaDcom(vin)])
        .then((results: [ServiceCase[], OrderAndAppointmentSearchResult[]]): void => {
          const serviceCases: ServiceCase[] = results[0];
          const dmsOrdersAndAppointments: OrderAndAppointmentSearchResult[] = this.filterDmsOrdersByServiceCases(
            results[1],
            serviceCases
          );
          if (serviceCases.length === 0 && dmsOrdersAndAppointments.length === 0) {
            this.openNewServiceCaseByVin(vin, origin);
          } else {
            this.offerExistingServiceCaseToOpen(serviceCases, dmsOrdersAndAppointments, vin, origin);
          }
        })
        .catch(reason => this.handleServiceCaseOpenError(reason));
    } else {
      this.openNewServiceCaseByVin(vin, origin);
    }
  }

  private fetchServiceCaseSession(vin: string): Promise<ServiceCaseSession> {
    return new Promise<ServiceCaseSession>((resolve, reject): void => {
      this.serviceCaseSessionLoader.getSessions(vin)
        .then((sessions: ServiceCaseSession[]): void => {
          // We only check for unlinked service case sessions, because a potentially linked service case was not found
          // by the system or was not selected by the user.
          sessions = sessions.filter((session: ServiceCaseSession) => !session.serviceCaseId);
          if (sessions.length === 0) {
            resolve(null);
          } else if (sessions.length === 1) {
            resolve(sessions[0]);
          } else {
            this.sessionSelectionOverlay.show(sessions)
              .then((session: ServiceCaseSession) => resolve(session))
              .catch(reject);
          }
        });
    });
  }

  selectAndOpenServiceCaseByDmsVehicle(dmsVehicle: DmsVehicle, origin: string): void {
    this.caseLoadingStarted.next();
    const user: UserData = this.userService.userSubject.getValue();
    const checkForExistingCases: boolean = !user.getNewCaseAfterVehicleIdentification();
    if (checkForExistingCases) {
      this.serviceCaseLoader.getServiceCasesByVin(dmsVehicle.vin)
        .then((serviceCases: ServiceCase[]): void => {
          serviceCases = this.filterServiceCasesByDmsOrder(serviceCases, dmsVehicle.orderNumber);
          if (serviceCases.length === 0) {
            this.openNewServiceCaseByDmsVehicle(dmsVehicle, origin);
          } else {
            this.offerExistingServiceCaseToOpenWithDmsVehicle(serviceCases, dmsVehicle, origin);
          }
        }).catch(reason => this.handleServiceCaseOpenError(reason));
    } else {
      this.openNewServiceCaseByDmsVehicle(dmsVehicle, origin);
    }
  }

  private offerExistingServiceCaseToOpen(serviceCases: ServiceCase[],
                                         dmsOrdersAndAppointments: OrderAndAppointmentSearchResult[],
                                         vin: string,
                                         origin: string): void {
    this.caseSelectionOverlay.show(serviceCases, dmsOrdersAndAppointments)
      .then((serviceCase: ServiceCase): void => {
        if (serviceCase?.isPersisted()) {
          this.openExistingServiceCaseById(serviceCase.getExternalId(), origin)
            .finally((): void => {
              // do nothing
            });
        } else if (serviceCase) {
          // User has selected pre-existing DMS order, see DmsOrderForVinSelectionComponent.
          // This creates a new, not yet persisted service case with rudimentary data.
          this.createAndOpenServiceCase(serviceCase, origin);
        } else {
          this.openNewServiceCaseByVin(vin, origin);
        }
      })
      .catch(() => this.caseLoadingFinished.next());
  }

  private offerExistingServiceCaseToOpenWithDmsVehicle(serviceCases: ServiceCase[],
                                                       dmsVehicleSearchResult: DmsVehicle,
                                                       origin: string): void {
    this.caseSelectionOverlay.show(serviceCases, [])
      .then((serviceCase: ServiceCase): void => {
        if (serviceCase) {
          this.openExistingServiceCaseById(serviceCase.getExternalId(), origin)
            .finally((): void => {
              // do nothing
            });
        } else {
          this.openNewServiceCaseByDmsVehicle(dmsVehicleSearchResult, origin);
        }
      })
      .catch(() => this.caseLoadingFinished.next());
  }

  private filterServiceCasesByDmsOrder(serviceCases: ServiceCase[], orderNumber: string): ServiceCase[] {
    // filter for service cases that match selected dms order
    if (orderNumber) {
      serviceCases = serviceCases
        .filter((serviceCase: ServiceCase): boolean => serviceCase.getDmsOrderNumber() === orderNumber);
    }

    return serviceCases;
  }

  private filterDmsOrdersByServiceCases(ordersAndAppointments: OrderAndAppointmentSearchResult[],
                                        serviceCases: ServiceCase[]): OrderAndAppointmentSearchResult[] {
    // filter dms orders/appointments that do not match existing service cases
    return ordersAndAppointments.filter((order: OrderAndAppointmentSearchResult) => serviceCases
      .every((serviceCase: ServiceCase): boolean => serviceCase.getDmsOrderNumber() !== order.dmsId));
  }

  private searchOrdersAndAppointmentsViaDcom(vin: string): Promise<OrderAndAppointmentSearchResult[]> {
    return this.dmsService.searchOrdersAndAppointmentsViaDcom(vin);
  }

  private subscribeToRequestToOpenServiceCaseByVin(): void {
    this.awpLib.subscribeToOpenServiceCaseByVin(
      (vin: string, origin: ActorId): void => this.selectAndOpenServiceCaseByVin(vin, origin)
    );
  }

  restoreServiceCases(serviceCaseIds: string[], activeServiceCaseId: string): void {
    Promise
      .all(serviceCaseIds.map((externalId: string) => new Promise<void>(resolve => {
        if (this.serviceCaseHolder.isServiceCaseOpen(externalId)) {
          // ignore already opened service cases
          resolve();
        } else {
          // If active service case could not been opened before, here is a retry. Even if that fails again, we will
          // have at least an active service case (with error message).
          const setActive: boolean = externalId === activeServiceCaseId;
          this.reloadServiceCaseById(externalId, setActive).then(resolve);
        }
      })))
      .then(() => this.serviceCaseHolder.sortServiceCases((left: ServiceCase, right: ServiceCase) =>
        serviceCaseIds.indexOf(left.getExternalId()) - serviceCaseIds.indexOf(right.getExternalId())
      ));
  }

  // endregion load service cases

  // region open service cases

  private openServiceCase(serviceCase: ServiceCase, setActive: boolean): void {
    this.serviceCaseHolder.openServiceCaseOrSetActive(serviceCase, setActive);
    this.caseLoadingFinished.next();
    this.vehicleDataLoader.loadVehicleRelatedData(serviceCase)
      .then((mustSaveServiceCase: boolean) => this.onServiceCaseUpdated(serviceCase, mustSaveServiceCase));
  }

  onServiceCaseUpdated(serviceCase: ServiceCase, mustSaveServiceCase: boolean): void {
    if (mustSaveServiceCase) {
      this.serviceCaseUpdater.saveServiceCaseMetadata(serviceCase);
    }
    if (serviceCase.getExternalId() === this.serviceCaseHolder.getActiveCase()?.getExternalId()) {
      this.serviceCaseHolder.onActiveCaseChanged();
    }
  }

  private handleServiceCaseOpenError(reason: Error): void {
    this.awpLib.notifyError(reason.message);
    this.caseLoadingFinished.next();
  }

  // endregion open service cases

  // region update service cases

  private onTranslationChange(): void {
    this.serviceCaseHolder.serviceCases.forEach((serviceCase: ServiceCase): void => {
      const setActive: boolean = serviceCase.getExternalId() ===
        this.serviceCaseHolder.getActiveCase()?.getExternalId();
      this.reloadServiceCaseById(serviceCase.getExternalId(), setActive)
        .finally((): void => {
          // do nothing
        });
    });
  }

  // endregion update service cases
}
