import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {
  DsBoxModule,
  DsButtonModule,
  DsFormsModule,
  DsIconModule,
  DsListItemModule,
  DsMessageModule,
  DsModalModule,
  DsNavigationBarModule,
  DsProgressCircleModule,
  DsSwitchModule,
  DsTabsModule,
  DsTagModule,
  DsToggleButtonModule,
  DsTooltipDirectiveModule
} from '@bmw-ds/components';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AgGridModule} from 'ag-grid-angular';
import {AppRoutingModule} from './app-routing.module';
import {WenInterceptor} from './auth/oidc/wen-interceptor';
import {AppComponent} from './components/app.component';
import {ConfigurationOverlayComponent} from './components/configuration-overlay/configuration-overlay.component';
import {CustomerSearchComponent} from './components/dms-search/customer-search/customer-search.component';
import {CustomerSourcePipe} from './components/dms-search/customer-search/customer-source.pipe';
import {DmsLicensePlateSearchComponent} from './components/dms-search/dms-license-plate-search.component';
import {DmsOrderSearchComponent} from './components/dms-search/dms-order-search.component';
import {DmsSearchMessageComponent} from './components/dms-search/dms-search-message.component';
import {HeaderComponent} from './components/header/header.component';
import {IframeComponent} from './components/iframe/iframe.component';
import {KeyPoolComponent} from './components/key-pool/key-pool.component';
import {KoreaDisclaimerComponent} from './components/korea-disclaimer/korea-disclaimer.component';
import {
  CaseSelectionOverlayComponent
} from './components/service-case-bar/case/selection/case-selection-overlay.component';
import {
  DmsOrderForVinSelectionComponent
} from './components/service-case-bar/case/selection/dms-order-for-vin-selection.component';
import {
  ServiceCaseForVinSelectionComponent
} from './components/service-case-bar/case/selection/service-case-for-vin-selection.component';
import {
  ServiceCaseSessionSelectionComponent
} from './components/service-case-bar/case/selection/service-case-session-selection.component';
import {ServiceCaseBarComponent} from './components/service-case-bar/case/service-case-bar.component';
import {
  ServiceCaseReloadOverlayComponent
} from './components/service-case-bar/case/service-case-reload-overlay/service-case-reload-overlay.component';
import {ServiceCaseTabComponent} from './components/service-case-bar/case/service-case-tab/service-case-tab.component';
import {ShortlistComponent} from './components/service-case-bar/case/shortlist/shortlist.component';
import {
  CustomerLanguagePopupComponent
} from './components/service-case-bar/customer/customer-language-popup/customer-language-popup.component';
import {SideNavigationComponent} from './components/side-navigation/side-navigation.component';
import {UserDropdownComponent} from './components/user-dropdown/user-dropdown.component';
import {UserSettingsOverlayComponent} from './components/user-dropdown/user-settings-overlay.component';
import {VehicleDataOverlayComponent} from './components/vehicle/vehicle-data-overlay/vehicle-data-overlay.component';
import {
  VehicleSearchOverlayTabComponent
} from './components/vehicle/vehicle-search-overlay/vehicle-search-overlay-tab/vehicle-search-overlay-tab.component';
import {
  VehicleSearchOverlayComponent
} from './components/vehicle/vehicle-search-overlay/vehicle-search-overlay.component';
import {
  VehicleSelectionOverlayComponent
} from './components/vehicle/vehicle-selection-overlay/vehicle-selection-overlay.component';
import {WebComponentComponent} from './components/web-component/web-component.component';
import {ResponseTimeInterceptorService} from './services/response-time-interceptor.service';
import {
  ShoppingCartButtonComponent
} from "./components/service-case-bar/case/shopping-cart-button/shopping-cart-button.component";

@NgModule({
  declarations: [
    AppComponent,
    ServiceCaseBarComponent,
    UserSettingsOverlayComponent,
    HeaderComponent,
    CaseSelectionOverlayComponent,
    VehicleSelectionOverlayComponent,
    IframeComponent,
    WebComponentComponent,
    VehicleSearchOverlayComponent,
    ConfigurationOverlayComponent,
    DmsLicensePlateSearchComponent,
    DmsOrderForVinSelectionComponent,
    DmsOrderSearchComponent,
    DmsSearchMessageComponent,
    ServiceCaseBarComponent,
    ServiceCaseForVinSelectionComponent,
    ServiceCaseSessionSelectionComponent,
    UserDropdownComponent,
    SideNavigationComponent,
    ShortlistComponent,
    ServiceCaseReloadOverlayComponent,
    ServiceCaseTabComponent,
    KeyPoolComponent,
    VehicleSearchOverlayTabComponent,
    CustomerSearchComponent,
    CustomerSourcePipe,
    KoreaDisclaimerComponent,
    CustomerLanguagePopupComponent,
    VehicleDataOverlayComponent
  ],
  imports: [
    NoopAnimationsModule,
    AgGridModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    DsListItemModule,
    DsButtonModule,
    DsFormsModule,
    DsBoxModule,
    DsModalModule,
    DsTabsModule,
    DsNavigationBarModule,
    DsMessageModule,
    DsProgressCircleModule,
    DsIconModule,
    DsSwitchModule,
    DsTagModule,
    DsToggleButtonModule,
    DsIconModule.forRoot({
      sprite: '/assets/img/custom_icons.svg'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DsTooltipDirectiveModule,
    ShoppingCartButtonComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseTimeInterceptorService,
      multi: true
    }
  ]
})
export class AppModule {
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
