import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Brand, IServerApi, IVehicle, Vehicle} from '@service-and-repairs/awpintegrationlib';
import {
  VehicleSelectionOverlayComponent
} from '../../../components/vehicle/vehicle-selection-overlay/vehicle-selection-overlay.component';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {ServiceCase} from '../../service-case/models/service-case';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {VehicleWarningLoader} from '../../vehicle-warning/services/vehicle-warning.loader';
import {VehicleService} from './vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleLoader {

  vehicleSelectionOverlay: VehicleSelectionOverlayComponent;

  private readonly awpLib: IServerApi;

  constructor(awpClientLibService: AwpClientLibService,
              private readonly translate: TranslateService,
              private readonly userService: UserService,
              private readonly vehicleService: VehicleService,
              private readonly vehicleWarningLoader: VehicleWarningLoader) {
    this.awpLib = awpClientLibService.serverApi;
  }

  static isValidVin7(vin: string): boolean {
    return vin?.length === 7;
  }

  static isValidVin17(vin: string): boolean {
    return vin?.length === 17;
  }

  static isValidTypeCode(code: string): boolean {
    return code?.length === 4;
  }

  loadVehicleInServiceCase(serviceCase: ServiceCase): Promise<boolean> {
    return new Promise(resolve => {
      if (serviceCase.getVehicle() && !serviceCase.vehicleDataMissing()) {
        resolve(this.updateVehicleInServiceCase(serviceCase, serviceCase.getVehicle()));
      } else if (serviceCase.getVinLong() ?? serviceCase.getVinShort()) {
        this.getVehicle(serviceCase).then((mustSaveServiceCase: boolean): void => resolve(mustSaveServiceCase));
      } else if (serviceCase.getTypeCode()) {
        this.getVirtualVehicle(serviceCase).then((mustSaveServiceCase: boolean): void => resolve(mustSaveServiceCase));
      } else {
        resolve(this.updateVehicleInServiceCase(serviceCase, null));
      }
    });
  }

  private updateVehicleInServiceCase(serviceCase: ServiceCase, vehicle: IVehicle): boolean {
    vehicle?.setLicensePlate(serviceCase.getLicensePlate());
    serviceCase.updateVehicle(vehicle);
    return this.vehicleWarningLoader.addVehicleWarnings(serviceCase);
  }

  private selectVehicle(vehicles: IVehicle[]): Promise<IVehicle> {
    return new Promise((resolve, reject): void => {
      if (vehicles.length > 1) {
        this.vehicleSelectionOverlay
          .show(vehicles)
          .then((vehicle: IVehicle) => resolve(vehicle))
          .catch((): void => {
            this.awpLib.notifyWarning(this.translate.instant('vehicle.selectionAborted'));
            reject(new Error());
          });
      } else {
        resolve(vehicles[0]);
      }
    });
  }

  private getVehicle(serviceCase: ServiceCase): Promise<boolean> {
    const user: UserData = this.userService.userSubject.getValue();
    const vin: string = serviceCase.getVinLong() ?? serviceCase.getVinShort();
    return new Promise(resolve => {
      this.vehicleService.getVehicles(vin, user)
        .then((vehicles: IVehicle[]): void => {
          this.selectVehicle(vehicles)
            .then((vehicle: IVehicle) => resolve(this.updateVehicleInServiceCase(serviceCase, vehicle)))
            .catch(() => resolve(this.updateVehicleInServiceCase(
              serviceCase,
              this.getUnidentifiedVehicle(serviceCase)
            )));
        })
        .catch(() => resolve(this.handleFailedVehicleIdentification(serviceCase)));
    });
  }

  private getVirtualVehicle(serviceCase: ServiceCase): Promise<boolean> {
    const user: UserData = this.userService.userSubject.getValue();
    return new Promise(resolve => {
      this.vehicleService.getVirtualVehicle(serviceCase.getTypeCode(), user)
        .then((vehicle: IVehicle) => resolve(this.updateVehicleInServiceCase(serviceCase, vehicle)))
        .catch(() => resolve(this.updateVehicleInServiceCase(serviceCase, this.getUnidentifiedVehicle(serviceCase))));
    });
  }

  private getUnidentifiedVehicle(serviceCase: ServiceCase): IVehicle {
    return Vehicle.fromPlainObject({
      vin: serviceCase.getVinLong(),
      vin7: serviceCase.getVinShort(),
      typeCode: serviceCase.getTypeCode(),
      brand: serviceCase.getBrand() ? Brand[serviceCase.getBrand()] : Brand.UNKNOWN,
      licensePlate: serviceCase.getLicensePlate(),
      modelDesignation: serviceCase.getModelDesignation(),
      developmentSeries: serviceCase.getDevelopmentCode(),
      imageUrlSmall: serviceCase.getSmallCosyImageUrl()
    });
  }

  private handleFailedVehicleIdentification(serviceCase: ServiceCase): boolean {
    if (!serviceCase.getVinLong()) {
      // Vehicle identification failed and there is only a VIN7. Show warning as some AWP functionality
      // requires a VIN17.
      this.awpLib.notifyWarning(this.translate.instant('case.notificationVehicleLoadError'));
    }
    return this.updateVehicleInServiceCase(serviceCase, this.getUnidentifiedVehicle(serviceCase));
  }
}
