import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {ServiceCase} from '../../service-case/models/service-case';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {ServiceCaseSession} from '../interfaces/service-case.session';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseSessionService {

  constructor(private readonly httpClient: HttpClient,
              private readonly userService: UserService) {
  }

  getSessions(vin: string, user: UserData): Promise<ServiceCaseSession[]> {
    const baseUrl: string = user.getContext() === 'B2D'
      ? environment.urls.serviceCaseSessionManagementService.b2d
      : environment.urls.serviceCaseSessionManagementService.b2dIntranet;

    return new Promise<ServiceCaseSession[]>(resolve => {
      this.httpClient.get(
        baseUrl + '/api/v1/service-cases'
        + '?dpNumber=' + user.getBusinessPartner().getDistributionPartnerNumber()
        + '&outlet=' + user.getBusinessPartner().getOutletNumber()
        + '&vin=' + vin
      )
        .subscribe({
          next: (sessions: ServiceCaseSession[]) => resolve(sessions),
          error: () => resolve([])
        });
    });
  }

  createSession(serviceCase: ServiceCase): Promise<string> {
    const user: UserData = this.userService.userSubject.getValue();
    if (!user || user?.getContext() === 'B2E') {
      return Promise.resolve(undefined);
    }

    const baseUrl: string = user.getContext() === 'B2D'
      ? environment.urls.serviceCaseSessionManagementService.b2d
      : environment.urls.serviceCaseSessionManagementService.b2dIntranet;

    return new Promise<string>(resolve => {
      this.httpClient.post(
        baseUrl + '/api/v1/ingestion/service-cases/awp',
        serviceCase
      )
        .subscribe({
          next: (data: ServiceCaseSession) => resolve(data.id),
          error: () => resolve(undefined)
        });
    });
  }

  updateSession(serviceCase: ServiceCase): void {
    const user: UserData = this.userService.userSubject.getValue();
    if (!user || user?.getContext() === 'B2E') {
      return;
    }

    const baseUrl: string = user.getContext() === 'B2D'
      ? environment.urls.serviceCaseSessionManagementService.b2d
      : environment.urls.serviceCaseSessionManagementService.b2dIntranet;

    this.httpClient.patch(
      baseUrl + '/api/v1/service-cases/' + serviceCase.getSccId() + '/awp',
      serviceCase
    )
      .subscribe();
  }

  updateSessionLastOpened(serviceCase: ServiceCase): void {
    const user: UserData = this.userService.userSubject.getValue();
    if (!user || user?.getContext() === 'B2E') {
      return;
    }

    const baseUrl: string = user.getContext() === 'B2D'
      ? environment.urls.serviceCaseSessionManagementService.b2d
      : environment.urls.serviceCaseSessionManagementService.b2dIntranet;

    this.httpClient.patch(
      baseUrl + '/api/v1/service-cases/' + serviceCase.getSccId() + '/awp/' + serviceCase.getExternalId() + '/opened',
      null
    )
      .subscribe();
  }
}
