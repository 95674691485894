import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBusinessPartner} from '@service-and-repairs/awpintegrationlib';
import {WarrantyRestriction} from '../interfaces/warranty-restriction';

@Injectable({
  providedIn: 'root'
})
export class WarrantyRestrictionService {
  constructor(private httpClient: HttpClient) {
  }

  getWarrantyRestrictions(vin: string,
                          locale: string,
                          country: string,
                          businessPartner: IBusinessPartner): Promise<WarrantyRestriction[]> {
    return new Promise((resolve, reject): void => {
      let url: string = `/api/warranty-restrictions/v2?vin17=${vin}&locale=${locale}&country=${country}`;
      if (businessPartner) {
        url = url
          + `&distribution_partner_number=${businessPartner.getDistributionPartnerNumber()}`
          + `&outlet_number=${businessPartner.getOutletNumber()}`;
      }
      this.httpClient.get(url, {withCredentials: true})
        .subscribe({
          next: (response: any) => resolve(response.data.restrictions),
          error: response => reject(response.errors)
        });
    });
  }
}
