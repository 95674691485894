<ds-modal #scss
          [ds-modal-trigger-for]="scss"
          [isModalOpen]="sessions.length > 1"
          (dismiss)="close()"
          (modalDismiss)="close()"
          [dimensionsConfig]="{height: '90vh'}">
  <ds-box-header>{{ 'case.selectSession' | translate }}</ds-box-header>
  <ds-box-content class="overlay-box-content">
    <p>{{ 'case.existingSessions' | translate }}</p>
    <div>
      @for (session of sessions; track session.id) {
        <div class="session-tile">
          <div class="meta-data container-fluid" (click)="sessionSelected(session)">
            <div class="row">
              <div class="col-3">
                <div class="text-label">{{ 'case.created' | translate }}</div>
                <div class="value bold"
                     [title]="session.createdAt | date:'mediumDate':'undefined':locale">
                  {{ session.createdAt | date:'mediumDate':'undefined':locale }}
                </div>
              </div>
              <div class="col-3">
                <div class="text-label">{{ 'case.creator' | translate }}</div>
                <div class="value bold" [title]="session.createdBy"> {{ session.createdBy }}</div>
              </div>
              <div class="col-3">
                <div class="text-label">{{ 'case.source' | translate }}</div>
                <div class="value bold" [title]="getSources(session)"> {{ getSources(session) }}</div>
              </div>
              <div class="col-3">
                <div class="text-label">{{ 'case.status' | translate }}</div>
                <div class="value bold" [title]="session.sessionStatus"> {{ session.sessionStatus }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <div class="text-label">{{ 'case.modified' | translate }}</div>
                <div class="value bold"
                     [title]="session.updatedAt | date:'mediumDate':'undefined':locale">
                  {{ session.updatedAt | date:'mediumDate':'undefined':locale }}
                </div>
              </div>
              <div class="col-3">
                <div class="text-label">{{ 'case.owner' | translate }}</div>
                <div class="value bold" [title]="session.updatedBy"> {{ session.updatedBy }}</div>
              </div>
              <div class="col-3">
                <div class="text-label">{{ 'case.appointment' | translate }}</div>
                <div class="value bold"
                     [title]="session.sessionData?.appointmentStart | date:'mediumDate':'undefined':locale">
                  {{ session.sessionData?.appointmentStart | date:'mediumDate':'undefined':locale }}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </ds-box-content>
</ds-modal>
