import {Injectable} from '@angular/core';
import {KeyDataLoader} from '../../keydata/services/key-data.loader';
import {ServiceCase} from '../../service-case/models/service-case';
import {UserService} from '../../user/services/user.service';
import {MileageRecord} from '../interfaces/mileage-record';
import {MileageService} from './mileage.service';

@Injectable({
  providedIn: 'root'
})
export class MileageLoader {
  constructor(private keyDataLoader: KeyDataLoader,
              private mileageService: MileageService,
              private userService: UserService) {
  }

  loadMileage(serviceCase: ServiceCase): Promise<boolean> {
    if (!serviceCase.getVinLong()) {
      return Promise.resolve(this.updateMileageInServiceCase(serviceCase, null));
    }

    const user = this.userService.userSubject.getValue();
    return new Promise(resolve => {
      this.mileageService.getMileageRecord(serviceCase.getVinLong(), user)
        .then(mileageRecord => resolve(this.updateMileageInServiceCase(serviceCase, mileageRecord)))
        .catch(() => {
          // get mileage from key data
          const mileageRecord = this.keyDataLoader.getMileageForVin(
            serviceCase.getVinLong(),
            serviceCase.getVehicle().getVehicleType()
          );
          resolve(this.updateMileageInServiceCase(serviceCase, mileageRecord));
        });
    });
  }

  private updateMileageInServiceCase(serviceCase: ServiceCase, mileageRecord: MileageRecord): boolean {
    const user = this.userService.userSubject.getValue();

    return serviceCase.setCurrentMileage(
      mileageRecord,
      user.getMileageUnit(),
      user.getLocale()
    );
  }
}
