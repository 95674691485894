import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ActorId, IServerApi} from '@service-and-repairs/awpintegrationlib';
import {Subscription} from 'rxjs';
import {AuthenticationTokenBearerService} from '../../auth/authentication-token-bearer.service';
import {ConfigurationLoader} from '../../core/configuration/services/configuration.loader';
import {ServiceCaseHolder} from '../../core/service-case/models/service-case-holder';
import {VehicleLoader} from '../../core/vehicle/services/vehicle.loader';
import {IIframeAppConfiguration} from '../../interfaces/IIframeAppConfiguration';
import {AwpClientLibService} from '../../services/awp-client-lib.service';
import {WebAgentCookieRetriever} from './web-agent-cookie-retriever';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnDestroy, OnInit {

  private static readonly IFRAME_LOADING_TIMEOUT_IN_MS: number = 10000;

  appConfiguration: IIframeAppConfiguration;
  showVinLongRequiredError: boolean = false;
  showWebAgentCookieOpenError: boolean = false;
  showAirLoginWindowInfo: boolean = false;
  showLoadingIndicator: boolean = true;

  private readonly routerState: any;
  private readonly webAgentSecuredPopupUrl: string;
  private loadingTimeout: number;
  private iframeElement: HTMLIFrameElement;
  private readonly awpLib: IServerApi;
  private webAgentCookieAlreadyRetrieved: boolean = false;

  private activeServiceCaseSubscription: Subscription;
  private outletConfigSubscription: Subscription;
  private oidcTokenRefreshSubscription: Subscription;

  constructor(private readonly route: ActivatedRoute,
              private readonly configurationLoader: ConfigurationLoader,
              public serviceCaseHolder: ServiceCaseHolder,
              private readonly authenticationTokenBearerService: AuthenticationTokenBearerService,
              awpClientLibService: AwpClientLibService,
              router: Router) {
    this.routerState = router.getCurrentNavigation()?.extras
      ? router.getCurrentNavigation().extras.state
      : {};
    this.appConfiguration = route.snapshot.data['appConfiguration'];
    this.awpLib = awpClientLibService.serverApi;
    this.webAgentSecuredPopupUrl = route.snapshot.data['webAgentUrl'];
  }

  private static hideAllIframes(): void {
    Array.from(document.getElementsByClassName('iframe-component-iframe'))
      .forEach((iframe: HTMLElement): string => iframe.style.display = 'none');
  }

  ngOnInit(): void {
    this.iframeElement = document
      .getElementsByClassName(this.appConfiguration.routingPath)
      .item(0) as HTMLIFrameElement;
    if (this.webAgentSecuredPopupUrl) {
      this.periodicallyObtainWebAgentCookie(!this.iframeElement
        ? this.prepareNewIframeElement.bind(this)
        : this.showExistingIframeElement.bind(this));
    } else if (!this.iframeElement) {
      this.prepareNewIframeElement();
    } else {
      this.showExistingIframeElement();
    }
  }

  ngOnDestroy(): void {
    this.clearLoadingTimeout();
    this.awpLib.unsubscribeFromClientInitializations();
    this.unsubscribeFromUrlRelevantChanges();
    this.oidcTokenRefreshSubscription?.unsubscribe();
    this.hideIframe();
  }

  /**
   * Check if an app is active (i.e. the user has not navigated away).
   */
  private isAppActive(): boolean {
    return window.location.pathname.includes(this.appConfiguration.routingPath);
  }

  private showIframe(): void {
    this.hideLoadingIndicator();
    IframeComponent.hideAllIframes();
    if (this.isAppActive()) {
      this.iframeElement.style.display = 'block';

      // ETK layout breaks if case is updated while hidden, publish again to fix it
      if (this.appConfiguration.routingPath === 'etk') {
        setTimeout(() => this.awpLib.publishActiveCase(this.serviceCaseHolder.getActiveCase()), 500);
      }
    }
  }

  private createNewIframeElement(): void {
    this.iframeElement = document.createElement('iframe');
    this.iframeElement.style.display = 'none';
    this.iframeElement.classList.add(this.appConfiguration.routingPath, 'iframe-component-iframe');
    this.iframeElement.name = this.appConfiguration.routingPath;
    this.appendIframeToViewport();
  }

  private prepareNewIframeElement(): void {
    this.createNewIframeElement();
    this.subscribeToUrlRelevantChanges();
  }

  private showExistingIframeElement(): void {
    this.handleClientInitialized();
    this.subscribeToUrlRelevantChanges();
  }

  private appendIframeToViewport(): void {
    document.getElementsByClassName('awp-viewport')
      .item(0)
      .appendChild(this.iframeElement);
  }

  private periodicallyObtainWebAgentCookie(webAgentCookieRetrievedCallback: () => void): void {
    this.oidcTokenRefreshSubscription = this.authenticationTokenBearerService.oidcTokenSubject
      .subscribe((): void => {
        if (this.appConfiguration.routingPath === 'air') {
          this.showAirLoginWindowInfoMessage();
        }
        new WebAgentCookieRetriever(this.appConfiguration.routingPath, this.webAgentSecuredPopupUrl)
          .openPopupToSetWebAgentCookie()
          .then((): void => {
            this.showAirLoginWindowInfo = false;
            if (!this.webAgentCookieAlreadyRetrieved) {
              this.webAgentCookieAlreadyRetrieved = true;
              webAgentCookieRetrievedCallback();
            }
          })
          .catch((): void => {
            if (!this.webAgentCookieAlreadyRetrieved) {
              this.showWebAgentCookieErrorMessage();
            }
          });
      });
  }

  private isVinRequirementFulfilled(): boolean {
    return !this.appConfiguration.vinLongRequired
      || VehicleLoader.isValidVin17(this.serviceCaseHolder.getActiveCase()?.getVinLong());
  }

  // will have no effect if iframe is already shown
  private showIFrameAfterClientInitialization(): void {
    if (this.loadingTimeout) {
      return; // previous call will show iframe
    }
    const actorId: ActorId = this.appConfiguration.actorId;
    if (actorId) {
      this.setTimeoutForWaitingForClientInitialization();
      this.awpLib.subscribeToClientInitializations((initializedActorId: ActorId): void => {
        if (initializedActorId === actorId) {
          this.handleClientInitialized();
        }
      });
    } else {
      this.handleClientInitialized();
    }
  }

  private setTimeoutForWaitingForClientInitialization(): void {
    this.loadingTimeout = window.setTimeout(
      () => this.handleClientInitialized(),
      IframeComponent.IFRAME_LOADING_TIMEOUT_IN_MS
    );
  }

  private handleClientInitialized(): void {
    this.clearLoadingTimeout();
    this.awpLib.unsubscribeFromClientInitializations();
    this.showIframe();
  }

  private clearLoadingTimeout(): void {
    clearTimeout(this.loadingTimeout);
    this.loadingTimeout = undefined;
  }

  private updateIframeUrlIfRequirementsAreFulfilled(): void {
    if (this.isVinRequirementFulfilled()) {
      this.updateIframeUrl();
      this.showIFrameAfterClientInitialization();
      this.hideVinRequiredErrorMessage();
    } else {
      this.showVinRequiredErrorMessage();
    }
  }

  private updateIframeUrl(): void {
    const iframeUrl: string = this.route.snapshot.data['iFrameUrl']
      + this.findPathParams()
      + this.collectQueryParams();

    // only update src if it has changed, replace encoded blanks for equality check
    if (!this.isIframeUrlEqual(iframeUrl)) {
      this.setIframeUrl(iframeUrl);
    }
  }

  /**
   * Iframe urls are equal if they only differ in escaped blank spaces or unnecessary slashes, when no path parameter
   * was applied.
   */
  private isIframeUrlEqual(iframeUrl: string): boolean {
    return this.getIframeUrlForComparison() === iframeUrl.replace('/?', '?');
  }

  private getIframeUrlForComparison(): string {
    return this.iframeElement.src
      .replace(/%20/g, ' ') // replace escaped blank spaces
      .replace('/?', '?')  // remove slash before query param question mark
      .replace(/\/$/g, ''); // remove slash at the end
  }

  private setIframeUrl(iframeUrl: string): void {
    if (this.iframeElement.name === 'wvi') {
      // Because WVI takes some seconds to load it will be hidden and reloaded to avoid showing old data while loading.
      this.deleteIframeElement();
      this.createNewIframeElement();
    }
    this.iframeElement.src = iframeUrl;
  }

  private deleteIframeElement(): void {
    if (this.iframeElement) {
      this.iframeElement.parentElement.removeChild(this.iframeElement);
    }
  }

  private findPathParams(): string {
    if (this.routerState?.pathParam) {
      // use param from router state if set in NavigationService
      return `/${this.routerState.pathParam}`;
    } else if (this.route.snapshot.data['pathParam']) {
      // use param from RouteFactoryService if not present in router state
      return `/${this.route.snapshot.data['pathParam']}`;
    } else {
      return '';
    }
  }

  private collectQueryParams(): string {
    const attributes: object = {};
    const getAttributesFunc: object = this.route.snapshot.data['getAttributes'];
    if (typeof getAttributesFunc === 'function') {
      Object.assign(attributes, getAttributesFunc());
    }
    if (this.routerState?.queryParams) {
      Object.assign(attributes, this.routerState.queryParams);
    }
    return this.constructQueryStringFromAttributes(attributes);
  }

  private constructQueryStringFromAttributes(attributes: object): string {
    const queryString: string = '?' +
      Object.keys(attributes).map((key: string): string => `${key}=${attributes[key]}&`).join('');
    return queryString.substring(0, queryString.length - 1);
  }

  private subscribeToUrlRelevantChanges(): void {
    this.activeServiceCaseSubscription = this.serviceCaseHolder.activeServiceCaseChanged
      .subscribe(() => this.updateIframeUrlIfRequirementsAreFulfilled());

    // update iframe URL if outlet config changes
    this.outletConfigSubscription = this.configurationLoader.configChanged.subscribe({
        next: this.updateIframeUrlIfRequirementsAreFulfilled.bind(this),
        error: this.updateIframeUrlIfRequirementsAreFulfilled.bind(this)
      }
    );

    if (this.isB2E()) {
      // in B2E,iframe url will not be set by outlet configuration change
      this.updateIframeUrlIfRequirementsAreFulfilled();
    }
  }

  private isB2E(): boolean {
    return window.location.host.includes('.net') && !window.location.host.includes('b2d');
  }

  private unsubscribeFromUrlRelevantChanges(): void {
    this.activeServiceCaseSubscription?.unsubscribe();
    this.outletConfigSubscription?.unsubscribe();
  }

  private hideIframe(): void {
    if (this.iframeElement) {
      this.iframeElement.style.display = 'none';
    }
  }

  private hideLoadingIndicator(): void {
    this.showLoadingIndicator = false;
  }

  private hideVinRequiredErrorMessage(): void {
    this.showVinLongRequiredError = false;
  }

  private showVinRequiredErrorMessage(): void {
    this.hideLoadingIndicator();
    this.hideIframe();
    this.showWebAgentCookieOpenError = false;
    this.showAirLoginWindowInfo = false;
    this.showVinLongRequiredError = true;
  }

  private showWebAgentCookieErrorMessage(): void {
    this.hideLoadingIndicator();
    this.hideIframe();
    this.showVinLongRequiredError = false;
    this.showWebAgentCookieOpenError = true;
    this.showAirLoginWindowInfo = false;
  }

  private showAirLoginWindowInfoMessage(): void {
    this.hideLoadingIndicator();
    this.hideIframe();
    this.showVinLongRequiredError = false;
    this.showWebAgentCookieOpenError = false;
    this.showAirLoginWindowInfo = true;
  }
}
