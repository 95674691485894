import {Component} from '@angular/core';
import {ServiceCase} from '../../../../core/service-case/models/service-case';
import {OrderAndAppointmentSearchResult} from '../../../dms-search/order-and-appointment-search-result';

@Component({
  selector: 'app-case-selection',
  templateUrl: './case-selection-overlay.component.html',
  styleUrls: ['./case-selection-overlay.component.scss']
})
export class CaseSelectionOverlayComponent {
  caseList: ServiceCase[] = [];
  orderList: OrderAndAppointmentSearchResult[] = [];

  private serviceCaseSelectedCallback: (serviceCase: ServiceCase) => void;
  private newServiceCaseCallback: (newServiceCaseWithDmsData?: ServiceCase) => void;
  private cancelCallback: () => void;

  show(serviceCases: ServiceCase[], ordersAndAppointments: OrderAndAppointmentSearchResult[]): Promise<ServiceCase> {
    this.caseList = serviceCases;
    this.orderList = ordersAndAppointments;
    return new Promise<ServiceCase>((resolve, reject) => {
      this.serviceCaseSelectedCallback = resolve;
      this.newServiceCaseCallback = resolve;
      this.cancelCallback = reject;
    });
  }

  close(): void {
    this.clearLists();
    this.cancelCallback?.();
  }

  newServiceCaseSelected() {
    this.clearLists();
    this.newServiceCaseCallback?.();
  }

  serviceCaseSelected(serviceCase: ServiceCase): void {
    this.clearLists();
    this.serviceCaseSelectedCallback?.(serviceCase);
  }

  orderSelected(serviceCase: ServiceCase): void {
    this.clearLists();
    this.newServiceCaseCallback?.(serviceCase);
  }

  private clearLists(): void {
    this.caseList = [];
    this.orderList = [];
  }
}
