<ds-modal #tpl [ds-modal-trigger-for]="tpl" [isModalOpen]="visible"
          (dismiss)="close()">
  <ds-box-header>{{ 'vehicle.selectVehicle' | translate }}</ds-box-header>

  <ds-box-content>
    <p>{{ 'vehicle.nonUniqueVin' | translate }}</p>

    @for (vehicle of vehicleList; track vehicle) {
      <ds-box class="my-3x">
        <ds-box-content class="container-fluid" [isClickable]="true" (boxClick)="vehicleSelected(vehicle)">
          <div class="row">
            <div class="col-4">
              <h3>{{ vehicle.getBrand() | translate }}</h3>
              <h2>{{ vehicle.getDevelopmentSeries() }}</h2>
              <img src="{{vehicle.getImageUrlLarge()}}" alt="vehicle">
            </div>
            <div class="col-8">
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <div class="text-label">{{ 'vehicle.vin' | translate }}</div>
                    <div>{{ vehicle.getVin() }}</div>
                  </div>
                  <div class="col">
                    <div class="text-label">{{ 'vehicle.bodyType' | translate }}</div>
                    <div>{{ vehicle.getBodyType() }}</div>
                  </div>
                  <div class="col">
                    <div class="text-label">{{ 'vehicle.model' | translate }}</div>
                    <div>{{ vehicle.getModelDesignation() }}</div>
                  </div>
                  <div class="col">
                    <div class="text-label">{{ 'vehicle.engine' | translate }}</div>
                    <div>{{ vehicle.getEngineType() }}</div>
                  </div>
                  <div class="col">
                    <div class="text-label">{{ 'vehicle.productionDate' | translate }}</div>
                    <div>{{ vehicle.getProductionDate() | date:'mediumDate':'UTC':locale }}</div>
                  </div>
                  <div class="col">
                    <div class="text-label">{{ 'vehicle.type' | translate }}</div>
                    <div>{{ vehicle.getTypeCode() }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ds-box-content>
      </ds-box>
    }
  </ds-box-content>

  <ds-box-footer>
    <button ds-button variant="outline" (click)="close()">{{ 'common.cancel' | translate }}</button>
  </ds-box-footer>
</ds-modal>
